import { GlobalService } from "./global.service";
import { ErrorHandler, Injectable } from "@angular/core";
import { RentahausToastService } from "./toast.service";

@Injectable({
  providedIn: "root",
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(
    private globalService: GlobalService,
    private rentahausToastService: RentahausToastService
  ) {}

  handleError(error: any) {
    if (this.globalService.offlineOrBadConnection(error)) {
      this.rentahausToastService.showToast(
        "error",
        this.globalService.toasterTitle,
        "Internet is down!"
      );
    } 
    this.globalService.deletePendingStatus();
  }
}
