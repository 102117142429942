import { Store } from '@ngrx/store';
import { IEvent } from '../interfaces/common.interface';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {map, shareReplay} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {
    IInspection,
    ICurrentInspection,
    IInspectionEvent,
    InspectedRooms,
    IInspectionApprovalStatus,
    IInspectionApprovalStatusResponse
} from '../interfaces/inspection.interface';
import { IRoom } from '../interfaces/room.interface';
import { IAppState } from '../store/state/app.state';
import * as fromInspection from 'src/app/store/selectors/inspection/inspection.selector';
import * as inspectionActions from 'src/app/store/actions/inspection/inspection.actions';


const INVENTORY_URL = `${environment.url}/inventory`;

// Response Interfaces
interface IInspectionEventResponse { success: boolean; property_id: string; inspections: IInspectionEvent[]; }

@Injectable({
    providedIn: 'root'
})
export class InspectionService extends BaseService {

    hasShared: any = new BehaviorSubject(false);
    constructor(protected httpClient: HttpClient,
                private store: Store<IAppState>) {
        super(httpClient);
    }

    public get inspectionData(): IInspection {
        let inspectionData: IInspection;
        this.store.select(fromInspection.selectInspectionData).subscribe(d => inspectionData = d);
        if (!!inspectionData) {
            return inspectionData;
        }
    }

    public set inspectionData(inspection: IInspection) {
        this.store.dispatch(inspectionActions.actionSetInspection({
            inspectionData: inspection
        }));
    }
    public get inspectionEvent(): IEvent {
        return this.inspectionData.inspection.event;
    }

    public createInspection(propertyId: string): Observable<ICurrentInspection> {
        return this.httpClient.get<ICurrentInspection>(`${INVENTORY_URL}/${propertyId}/inspection`).pipe(
            map(res => {
                return res;
            })
        );
    }

    public performInspection(propertyId: string, eventId: string): Observable<ICurrentInspection> {
        return this.httpClient.get<ICurrentInspection>(`${INVENTORY_URL}/${propertyId}/performInspection?event_id=${eventId}`).pipe(
            map(res => {
                return res;
            })
        );
    }

    public fetchCurrentInspection(propertyId: string): Observable<ICurrentInspection> {
            return this.httpClient.get<ICurrentInspection>(`${INVENTORY_URL}/${propertyId}/currentInspection`).pipe(
                shareReplay(),
                map(res => {
                    return res;
                })
            );
    }

    public fetchInspectionApprovalStatus(propertyId: string, inspectionId: string, isCompletedEvent: boolean): Observable<IInspectionApprovalStatus> {
        const params = new HttpParams().set('isCompletedEvent', String(isCompletedEvent));
        return this.httpClient.get<IInspectionApprovalStatusResponse>(`${INVENTORY_URL}/${propertyId}/inspection/${inspectionId}/approvalStatus`, { params }).pipe(
            shareReplay(),
            map(res => {
                return res.approvalStatus;
            })
        );
    }

    public exitInspection(propertyId: string, inspection_id: string): Observable<ICurrentInspection> {
        const params = new HttpParams().set('inspection_id', String(inspection_id));
        return this.httpClient.delete<ICurrentInspection>(`${INVENTORY_URL}/${propertyId}/exitInspection`, { params }).pipe(
            map(res => {
                return res;
            })
        );
    }

    public isEveryRoomInspected(inventory: IRoom[]): boolean {
        return inventory.every(room => !!room.is_inspected);
    }

    public fetchInspectionEvents(propertyId: string): Observable<IInspectionEvent[]> {
            return this.httpClient.get<IInspectionEventResponse>(`${INVENTORY_URL}/${propertyId}/inspections`).pipe(
                map(res => {
                    res.inspections.forEach((event: IInspectionEvent) => {
                        event.performed_at_date = this.formatedDate(event.performed_at_date);
                    });
                    return res.inspections || [];
                })
            );
    }

  public formatedDate(date) {
    const formatedDate = new Date(date);
    const day = String(formatedDate.getDate()).padStart(2, '0');
    const month = String(formatedDate.getMonth() + 1).padStart(2, '0');
    const year = formatedDate.getFullYear();
    return `${day}/${month}/${year}`;
  }

    public fetchInspection(propertyId: string, inspectionId: string, shared_token?: string): Observable<IInspection> {
        let url: string;
        if (!!shared_token) {
            url = `${propertyId}/inspection/${inspectionId}?shared_token=${shared_token}`;
        } else {
            url = `${propertyId}/inspection/${inspectionId}`;
        }
        return this.httpClient.get<IInspection>(`${INVENTORY_URL}/${url}`).pipe(
            map(res => {
                return res;
            })
        );
    }

    public shareInspection(propertyId: string, inspectionId: string, data: { emails: string }): Observable<any> {
        return this.httpClient.post<IInspection>(`${INVENTORY_URL}/${propertyId}/inspection/${inspectionId}/share`, data);
    }

    public mutateInspectionState(propertyId: string, data: { inspection_id: string, state: string, value: boolean, token?: string }): Observable<any> {
        return this.httpClient.post<any>(`${INVENTORY_URL}/${propertyId}/mutateInspectionState`, data);
    }

    public verifySharedInspection(propertyId: string, inspectionId: string, data: { code: string, token: string }): Observable<any> {
        return this.httpClient.post<IInspection>(`${INVENTORY_URL}/${propertyId}/inspection/${inspectionId}/share/verification`, data);
    }

    public getInspectedRooms(): InspectedRooms[] {
        return this.inspectionData.inspection.inspectedRooms;
    }

}
