import { UserRequest } from "src/app/interfaces/user-request.interface";

export interface AdminInspectedPropertiesState {
  inspectedPropertiesDetail: any;
}

export const adminInspectedPropertiesIntialState: AdminInspectedPropertiesState =
  {
    inspectedPropertiesDetail: {},
  };
