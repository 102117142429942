import { Injectable } from "@angular/core";
import {
  Route,
  CanLoad,
  UrlSegment,
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { SignupService } from "../pages/auth/signup/signup.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanLoad, CanActivate {
  public constructor(
    private signUpService: SignupService,
    private router: Router
  ) {}

  public canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.signUpService.isUserAuthenticated) {
      this.router.navigate(["auth"]);
    }
    return this.signUpService.isUserAuthenticated;
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.signUpService.isAdminUser()) {
      this.router.navigate(["auth"]);
    }
    return this.signUpService.isAdminUser();
  }

  public canActive(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.signUpService.isUserAuthenticated) {
      this.router.navigate(["/property"]);
    }
    return !this.signUpService.isUserAuthenticated;
  }
}
