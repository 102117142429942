import { createAction, props } from '@ngrx/store';
import { IEvent } from '../../../interfaces/common.interface';

  export const actionSetEvents = createAction(
    '[Events] Set Events',
    props<{events: IEvent[]}>()
  );

  export const actionLoadEvents = createAction(
    '[Events] Load Events'
  );

  export const actionLoadEventsSuccess = createAction(
    '[Events] Load Events Success',
    props<{events: IEvent[]}>()
  );

  export const actionLoadEventsFail = createAction(
    'Load Events Fail',
    props<{error: string}>()
  );