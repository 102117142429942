import { ITerms } from '../../../interfaces/terms.interface';
import { createAction, props } from '@ngrx/store';

export const actionFetchTerms = createAction(
  '[Terms] Fetch Terms',
  props<{propertyId: string}>()
);

export const actionFetchTermsFail = createAction(
  '[Terms] Fetch Terms Fail',
  props<{error: string}>()
);

export const actionSetTerms = createAction(
  '[Terms] Set Terms',
  props<{terms: ITerms | null}>()
);

export const actionSaveTerms = createAction(
  '[Terms] Save a Terms',
  props<{terms: ITerms, propertyId: string}>()
);

export const actionSaveTermsSuccess = createAction(
  '[Terms] Save a Terms Success',
  props<{terms: ITerms}>()
);

export const actionSaveTermsFail = createAction(
  '[Terms] Save a Terms Fail',
  props<{error: string}>()
);

export const actionClearTermsSate = createAction(
  '[Terms] Clear Terms Sate'
);