import { createReducer, on, Action } from '@ngrx/store';
import * as meterReadingsEventsActions  from '../../actions/meterReadings/meterReadingsEvent.actions';
import { IMeterReadingsEventState, initialMeterReadingsEventState } from '../../state/meterReadings/meterReadingsEvent.state';

const reducer = createReducer(
    initialMeterReadingsEventState,
    on(meterReadingsEventsActions.actionSetMeterReadingsEvent, (state, {meterReadingsEvents})=>({
        ...state,
        meterReadingsEvents
    })),
    on(meterReadingsEventsActions.actionSetSortedReadingKeys, (state, {sortedReadingKeys})=>({
        ...state,
        sortedReadingKeys
    }))
);

export function meterReadingsEventReducers(state: IMeterReadingsEventState | undefined, action: Action) {
    return reducer(state, action);
}