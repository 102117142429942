export const REGISTER_INTEREST_ENDPOINT = '/account/register-interest';
export const APPROVE_INTEREST_ENDPOINT = '/account/approve-interested-user';
export const REGISTER_ENDPOINT = '/account/registration';
export const EMAIL_VERIFICATION_ENDPOINT = '/account/emailVerification';
export const EMAIL_LOGIN_ENDPOINT = '/account/login';
export const REQUEST_RESET_PASSWORD_ENDPOINT = '/account/password/reset/request';
export const CONFIRM_RESET_PASSWORD_ENDPOINT = '/account/password/reset/confirm';
export const SEARCH_ADDRESS_ENDPOINT = '/address/search';
export const CONTACT_US_ENDPOINT = '/contact/us';

