import {
  AdminInspectedPropertyDetailsAction,
  AdminInspectedpropertyDetailsType,
} from "../../actions/admin/propertyActivityDetails.actions";
import {
  adminInspectedPropertiesIntialState,
  AdminInspectedPropertiesState,
} from "../../state/admin/inspected-property.state";

export function adminInspectedPropertyReducer(
  state = adminInspectedPropertiesIntialState,
  action: AdminInspectedPropertyDetailsAction
): AdminInspectedPropertiesState {
  switch (action.type) {
    case AdminInspectedpropertyDetailsType.GetInspectedPropertyDetailsSuccess: {
      return {
        ...state,
        inspectedPropertiesDetail: action.InspectionPropertyDetails,
      };
    }

    default:
      return state;
  }
}
