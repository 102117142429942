import { KeysAndFobs, SecurityAlarm, Security } from 'src/app/interfaces/security.interface';

export interface ISecurityState {
    security: Security,
    isInspected: boolean
}

export const initialSecurityState: ISecurityState = {
    security: null,
    isInspected: null
};