import { initialSecurityState, ISecurityState } from '../../state/security/security.state';
import { createReducer, on, Action } from '@ngrx/store';
import * as securityActions  from '../../actions/security/security.actions';

const reducer = createReducer(
    initialSecurityState,
    on(securityActions.actionSaveSecuritySuccess, (state, {security})=>({
        ...state,
        security: security
    })),
    on(securityActions.actionSetSecurity, (state, {security})=>({
        ...state,
        security: security
    })),
    on(securityActions.actionSaveSecurityFail, (state, {})=>({
        ...state
    })),
    on(securityActions.actionClearSecurityState, () =>({ 
        ...initialSecurityState
    })),
    on(securityActions.actionToInspectSecurity, (state, {isInspected})=>({
        ...state,
        isInspected
    })),
);

export function securityReducers(state: ISecurityState | undefined, action: Action) {
    return reducer(state, action);
}