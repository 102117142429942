import { IReportsState, initialReportsState } from './../../state/report/reports.state';
import { createReducer, on, Action } from '@ngrx/store';
import * as reportsActions  from '../../actions/report/reports.actions';


const reducer = createReducer(
    initialReportsState,
    on(reportsActions.actionLoadReportsSuccess, (state, { reports })=>({
        ...state,
        reports
    })),
    on(reportsActions.actionLoadReportsFail, (state, { })=>({
        ...state,
        reports: []
    })),
    on(reportsActions.actionSetReportsExist, (state, { isReportsExist })=>({
        ...state,
        isReportsExist
    })),
    on(reportsActions.actionClearReportsState, () =>({ 
        ...initialReportsState
    }))
);

export function reportsReducers(state: IReportsState | undefined, action: Action) {
    return reducer(state, action);
}