import {
  AdminUserList,
  UserOverviewDetails,
} from "src/app/interfaces/user-list.interface";

export interface AdminUserListState {
  userList: AdminUserList[];
  userOverviewDetails: UserOverviewDetails[];
}

export const initialAdminUserListState: AdminUserListState = {
  userList: [],
  userOverviewDetails: [],
};
