
import { createReducer, on, Action } from '@ngrx/store';
import { initialInventoryState, IInventoryState } from '../../state/inventory/inventory.state';
import * as inventoryActions  from '../../actions/inventory/inventory.actions';

const reducer = createReducer(
    initialInventoryState,
    on(inventoryActions.actionCreateInventorySuccess, (state, {inventory})=>({
        ...state,
        inventory
    })),
    on(inventoryActions.actionCreateInventoryTemplateSuccess, (state, {templateType})=>({
        ...state,
        templateType
    })),
    on(inventoryActions.actionSetInventory, (state, {inventory})=>({
        ...state,
        inventory
    })),
    on(inventoryActions.actionCreateInventoryFail, (state, {})=>({
        ...state,
        inventory: null
    })),
    on(inventoryActions.actionSetInventoryTemplate, (state, {inventory})=>({
        ...state,
        inventory
    })),
    on(inventoryActions.actionFetchInventoryFail, (state, {})=>({
        ...state,
        inventory: null
    })),
    on(inventoryActions.actionClearInventoryState, () =>({
        ...initialInventoryState
    }))
);

export function inventoryReducers(state: IInventoryState | undefined, action: Action) {
    return reducer(state, action);
}

