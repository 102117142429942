import { IMeterReadingsEvent } from './../../../interfaces/meter-reading.interface';

export interface IMeterReadingsEventState {
    meterReadingsEvents: IMeterReadingsEvent,
    sortedReadingKeys: string[]
}

export const initialMeterReadingsEventState: IMeterReadingsEventState = {
    meterReadingsEvents: null,
    sortedReadingKeys: null
};