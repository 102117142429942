import { Action } from "@ngrx/store";

export enum AdminAssociatedPropertyTypes {
  GetAssociatedProperty = "[Admin] Get Associated Property",
  GetAssociatedPropertySuccess = "[Admin] Get Associated Property Success",
  GetAssociatedPropertyFailure = "[Admin] Get Associated Property Failure",
  ChangePropertyStatus = "[Admin] Change  Property Status",
  ChangePropertyStatusSuccess = "[Admin] Change  Property Status Success",
}

export class GetAssociatedProperty implements Action {
  readonly type = AdminAssociatedPropertyTypes.GetAssociatedProperty;
  constructor(public userId: string) {}
}

export class GetAssociatedPropertySuccess implements Action {
  readonly type = AdminAssociatedPropertyTypes.GetAssociatedPropertySuccess;
  constructor(public associatedProperties: any) {}
}

export class GetAssociatedPropertyFailure implements Action {
  readonly type = AdminAssociatedPropertyTypes.GetAssociatedPropertyFailure;
  constructor(public err: any) {}
}
export class ChangePropertyStatus implements Action {
  readonly type = AdminAssociatedPropertyTypes.ChangePropertyStatus;
  constructor(public status: boolean, public propertyId: string) {}
}
export class ChangePropertyStatusSuccess implements Action {
  readonly type = AdminAssociatedPropertyTypes.ChangePropertyStatusSuccess;
}

export type AdminAssociatedPropertyActions =
  | GetAssociatedProperty
  | GetAssociatedPropertySuccess
  | GetAssociatedPropertyFailure;
