import { Action } from "@ngrx/store";
import { UserOverviewDetails } from "src/app/interfaces/user-list.interface";

export enum AdminUserOverviewTypes {
  DeactivateUser = "[Admin] Deactivate User",
  DeactivateUserSuccess = "[Admin] Deactivate User Success",
  DeactivateUserFailure = "[Admin] Deactivate User Fail",
  ActionGetUserById = "[Admin] Get User",
  ActionGetUserByIdSuccess = "[Admin] Get User Success",
  ActionGetUserByIdFailure = "[Admin] Get User Fail",
  ActionDeleteUser = "[Admin] Delete User",
  ActionDeleteUserSuccess = "[Admin] Delete User Successfully",
  ActionDeleteUserFailure = "[Admin] Delete User Fail",
}

export class DeactivateUser implements Action {
  readonly type = AdminUserOverviewTypes.DeactivateUser;
  constructor(public userId: string) {}
}
export class DeactivateUserSuccess implements Action {
  readonly type = AdminUserOverviewTypes.DeactivateUserSuccess;
}
export class DeactivateUserFailure implements Action {
  readonly type = AdminUserOverviewTypes.DeactivateUserFailure;
  constructor(public payload: { error: any }) {}
}

export class ActionGetUserById implements Action {
  readonly type = AdminUserOverviewTypes.ActionGetUserById;
  constructor(public userId: string) {}
}
export class ActionGetUserByIdSuccess implements Action {
  readonly type = AdminUserOverviewTypes.ActionGetUserByIdSuccess;
  constructor(public userOverviewDetails: UserOverviewDetails[]) {}
}
export class ActionGetUserByIdFailure implements Action {
  readonly type = AdminUserOverviewTypes.ActionGetUserByIdFailure;
  constructor(public payload: { error: any }) {}
}
export class ActionDeleteUser implements Action {
  readonly type = AdminUserOverviewTypes.ActionDeleteUser;
  constructor(public userId: string) {}
}
export class ActionDeleteUserSuccess implements Action {
  readonly type = AdminUserOverviewTypes.ActionDeleteUserSuccess;
}
export class ActionDeleteUserFailure implements Action {
  readonly type = AdminUserOverviewTypes.ActionDeleteUserFailure;
}

export type AdminUserOverviewAction =
  | ActionGetUserByIdSuccess
  | ActionGetUserByIdFailure;
