import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { UnAuthGuard } from './services/un-auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
    canLoad: [UnAuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((mod) => mod.AuthModule),
      canLoad: [UnAuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then(
        (mod) => mod.ProfilePageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'property',
    loadChildren: () =>
      import('./pages/property-dashboard/property-dashboard.module').then(
        (mod) => mod.PropertyDashboardPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'auth/password/reset/confirm',
    loadChildren: () =>
      import('./pages/auth/new-password/reset-password-confirm.module').then(
        (mod) => mod.ResetPasswordConfirmModule
      ),
  },
  {
    path: 'verify',
    loadChildren: () =>
      import(
        './pages/verification-dashboard/verification-dashboard.module'
      ).then((mod) => mod.VerificationDashboardPageModule),
  },
  {
    path: 'thank-you',
    loadChildren: () =>
      import('./pages/thank-you/thank-you.module').then(
        (mod) => mod.ThankYouPageModule
      ),
  },
  {
    path: 'verify/interest/:id',
    loadChildren: () =>
      import('./pages/approve-interest/approve-interest.module').then(
        (mod) => mod.ApproveInterestPageModule
      ),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./pages/admin-dashboard/admin.module').then(
        (mod) => mod.AdminModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },

  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/menu/privacy-policy/privacy-policy.module').then(
        (mod) => mod.PrivacyPolicyPageModule
      ),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./pages/menu/contact-us/contact-us.module').then(
        (mod) => mod.ContactUsPageModule
      ),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import(
        './pages/menu/terms-and-conditions/terms-and-conditions.module'
      ).then((mod) => mod.TermsAndConditionsPageModule),
  },
  {
    path: 'inspection/:inspectionId/media',
    loadChildren: () =>
      import('./pages/pdf-media/pdf-media.module').then(
        (mod) => mod.PdfMediaPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
