import { createAction, props } from '@ngrx/store';

export const actionLogin = createAction(
  '[User] Set User',
  props<{ user: any }>()
);

export const actionSetUserToken = createAction(
  '[User] Set User Token',
  props<{ token: string }>()
);

export const actionSetExternalUserToken = createAction(
  '[User] Set External User Token',
  props<{ shared_token: string }>()
);

export const actionSetIsExternalUser = createAction(
  '[User] Set External User',
  props<{ is_external_user: boolean }>()
);

export const actionSetIsApprover = createAction(
  '[User] Set Approver',
  props<{ is_approver: boolean }>()
);

export const actionSetUserRole = createAction(
  '[User] Set User Role',
  props<{ role: string }>()
);

export const actionUserLogout = createAction(
  '[User] User logout'
);
