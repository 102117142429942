import { IPropertyType } from './../../../interfaces/rh-property.interface';
import { createAction, props } from '@ngrx/store';
import { IPropertyCreateForm } from '../../../interfaces/rh-property.interface';
import { IProperty } from "src/app/interfaces/rh-property.interface";

export const actionSetPropertyAddressForm = createAction(
  '[Property] Set Property Address Form',
  props<{propertyForm:IPropertyCreateForm}>()
);

export const actionSetPropertyRentalType = createAction(
  '[Property] Set Property Rental Type',
  props<{rentalType: string}>()
);

export const actionSetPropertyType = createAction(
  '[Property] Set Property Type',
  props<{propertyType: string}>()
);

export const actionCreateProperty = createAction(
  '[Property] Create a Property',
  props<{propertyForm:IPropertyCreateForm}>()
);
export const actionUpdateProperty = createAction(
  '[Property] Update a Property',
  props<{propertyId: string, propertyForm:IPropertyCreateForm, hasInventory?: boolean}>()
);

export const actionSetPropertyId = createAction(
  '[Property] Set Property Id',
  props<{propertyId: string}>()
);

export const actionLoadPropertyTypes = createAction(
  '[Property] Load Property types',
);

export const actionSetPropertyTypes = createAction(
  '[Property] Set Property types',
  props<{propertyTypes:IPropertyType[]}>()
);

export const actionSavePropertyFail = createAction(
  '[Property] Create a Property Fail',
  props<{error:string}>()
);

export const actionFetchProperty = createAction(
  '[Property] Fetch a Property',
  props<{propertyId: string, isCompletedEvent: boolean}>()
);

export const actionSetProperty = createAction(
  '[Property] Set a Property',
  props<{property:IProperty}>()
);

export const actionFetchPropertyFail = createAction(
  '[Property] Fetch Property Fail',
  props<{error: string}>()
);

export const actionSetAvailabilityStatus = createAction(
  '[Property] Set Availability Status',
  props<{key: string, value: boolean}>()
);


export const actionToActivate = createAction(
  '[Property] To activate the Property',
  props<{ propertyId: string, data: { activate: boolean }, isCompletedEvent: boolean}>()
);

export const actionToActivateSuccess = createAction(
  '[Property] To activate the Property Sucess',
  props<{ res: any }>()
);

export const actionToActivateFail = createAction(
  '[Property] To activate the Property Fail',
  props<{error: string}>()
);

export const actionPropertyResolverCall = createAction(
  '[Property] Property Resolver Call',
  props<{propertyResolverCall:boolean}>()
);

export const actionClearPropertyState = createAction(
  '[Property] Clear Property State',
);
