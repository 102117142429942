

import { initialPropertyListState, IPropertiesListState } from '../../state/property/propertiesList.state';
import { createReducer, on, Action } from '@ngrx/store';
import * as propertyListActions  from '../../actions/property/propertiesList.actions';

const reducer = createReducer(
  initialPropertyListState,
  on(propertyListActions.actionLoadPropertiesSuccess, (state, {properties})=>({
      ...state,
      properties: properties
    })),
  on(propertyListActions.actionSetProperties, (state, {properties})=>({
    ...state,
    properties: properties
  })),  
  on(propertyListActions.actionLoadPropertiesFail, (state, {})=>({
    ...state,
    properties: []
  })),
)

export function propertiesListReducers(state: IPropertiesListState | undefined, action: Action) {
  return reducer(state, action);
}