import { ISafety } from 'src/app/interfaces/safety.interface';

export interface ISafetyState {
    safety: ISafety,
    isInspected: boolean
}

export const initialSafetyState: ISafetyState = {
    safety: null,
    isInspected: null
};