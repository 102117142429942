import { createReducer, on, Action } from '@ngrx/store';
import * as inspectionActions  from '../../actions/inspection/inspection.actions';
import { IInspectionState, initialInspectionState } from '../../state/inspection/inspection.state';

const reducer = createReducer(
    initialInspectionState,
    on(inspectionActions.actionSetInspectionEvent, (state, {inspectionEvents})=>({
        ...state,
        inspectionEvents
    })),
    on(inspectionActions.actionLoadInspectionEventSuccess, (state, {inspectionEvents})=>({
        ...state,
        inspectionEvents
    })),
    on(inspectionActions.actionLoadInspectionEventFail, (state, {})=>({
        ...state,
        inspectionEvents: null
    })),
    on(inspectionActions.actionSetInspectionEventsExist, (state, { isInspectionEventsExist })=>({
        ...state,
        isInspectionEventsExist
    })),
    on(inspectionActions.actionSetCompletedInspectionEventsExist, (state, { isCompletedInspectionEventsExist })=>({
        ...state,
        isCompletedInspectionEventsExist
    })),
    on(inspectionActions.actionSetInspectionMode, (state, { isInspectionMode })=>({
        ...state,
        isInspectionMode
    })),
    on(inspectionActions.actionSetInspectionEventView, (state, { isViewInspection })=>({
        ...state,
        isViewInspection
    })),
    on(inspectionActions.actionFetchCurrentInspectionSuccess, (state, { inspectionData })=>({
        ...state,
        inspectionData
    })),
    on(inspectionActions.actionFetchInspectionApprovalStatusSuccess, (state, { approvalStatus })=>({
        ...state,
        approvalStatus
    })),
    on(inspectionActions.actionSetInspectionApprover, (state, { approver })=>({
        ...state,
        approver
    })),
    on(inspectionActions.actionFetchInspectionFail, (state, {  })=>({
        ...state,
        inspectionData: null
    })),
    on(inspectionActions.actionSetInspection, (state, { inspectionData })=>({
        ...state,
        inspectionData
    })),
    on(inspectionActions.actionMutateInspectionStateSuccess, (state, { key, value })=>({
        ...state,
        inspectionData : {...state.inspectionData, inspection: 
            {...state.inspectionData.inspection, [key]: value }}
    })),
    on(inspectionActions.actionMutateInspectionPropStateSuccess, (state, { key, value })=>({
        ...state,
        inspectionData : {...state.inspectionData, inspection: 
            {...state.inspectionData.inspection, property: 
                {...state.inspectionData.inspection.property, [key]: value }}}
    })),
    on(inspectionActions.actionClearInspectionState, () =>({
         ...initialInspectionState
    }))
);

export function inspectionReducers(state: IInspectionState | undefined, action: Action) {
    return reducer(state, action);
}