import { Action } from "@ngrx/store";
import { UserRequest } from "src/app/interfaces/user-request.interface";

export enum AdminInspectedpropertyDetailsType {
  GetInspectedPropertyDetails = "[Admin] Get Inspected Property Details",
  GetInspectedPropertyDetailsSuccess = "[Admin] Get Inspected Property Details Success",
  GetInspectedPropertyDetailsFailure = "[Admin] Get Inspected Property Details Failure",
  GetPropertyReports = "[Admin] Get Property Report",
  GetPropertyReportSuccess = "[Admin] Get Property Report Success",
  GetPropertyReportFailure = "[Admin] Get Property Report Failure",
  ActionGetInspectedProperties = "[Admin] Get Property Properties",
  ActionGetInspectedPropertiesSuccess = "[Admin] Get Property Properties Success",
  ActionGetInspectedPropertiesFailure = "[Admin] Get Property Properties Failure",
}

export class ActionGetInspectedPropertyDetails implements Action {
  readonly type = AdminInspectedpropertyDetailsType.GetInspectedPropertyDetails;
  constructor(public propertyId: string) {}
}
export class ActionGetInspectedPropertyDetailsSuccess implements Action {
  readonly type =
    AdminInspectedpropertyDetailsType.GetInspectedPropertyDetailsSuccess;
  constructor(public InspectionPropertyDetails: any) {}
}
export class ActionGetInspectedPropertyDetailsFailure implements Action {
  readonly type =
    AdminInspectedpropertyDetailsType.GetInspectedPropertyDetailsFailure;
  constructor(public err: any) {}
}
export class GetPropertyReports implements Action {
  readonly type = AdminInspectedpropertyDetailsType.GetPropertyReports;
  constructor(public propertyId: string) {}
}
export class GetPropertyReportSuccess implements Action {
  readonly type = AdminInspectedpropertyDetailsType.GetPropertyReportSuccess;
  constructor(public propertyReports: any) {}
}
export class GetPropertyReportFailure implements Action {
  readonly type = AdminInspectedpropertyDetailsType.GetPropertyReportFailure;
  constructor(public err: any) {}
}

export class GetInspectedProperties implements Action {
  readonly type =
    AdminInspectedpropertyDetailsType.ActionGetInspectedProperties;
  constructor(public propertyId: string) {}
}
export class ActionGetInspectedPropertiesSuccess implements Action {
  readonly type =
    AdminInspectedpropertyDetailsType.ActionGetInspectedPropertiesSuccess;
  constructor(public inspectedProperties: any) {}
}
export class ActionGetInspectedPropertiesFailure implements Action {
  readonly type =
    AdminInspectedpropertyDetailsType.ActionGetInspectedPropertiesFailure;
  constructor(public err: any) {}
}

export type AdminInspectedPropertyDetailsAction =
  | ActionGetInspectedPropertyDetailsFailure
  | ActionGetInspectedPropertyDetailsSuccess
  | GetPropertyReportSuccess
  | GetPropertyReportFailure
  | ActionGetInspectedPropertiesSuccess
  | ActionGetInspectedPropertiesFailure;
