import { createReducer, on, Action } from '@ngrx/store';
import { IDocumentTypeState, initialDocumentationTypeState } from '../../state/documentation/documentsType.state';
import * as documentTypeActions  from '../../actions/documentation/documentsType.actions';

const reducer = createReducer(
    initialDocumentationTypeState,
    on(documentTypeActions.actionSetDocumentsType, (state, {docsTypes})=>({
        ...state,
        docsTypes: docsTypes
    })),
    on(documentTypeActions.actionLoadDocumentsTypeSuccess, (state, {docsTypes})=>({
        ...state,
        docsTypes: docsTypes
    })),
    on(documentTypeActions.actionLoadDocumentsTypeFail, (state, {})=>({
        ...state,
        docsTypes: null
    }))

);

export function documentTypeReducers(state: IDocumentTypeState | undefined, action: Action) {
    return reducer(state, action);
}