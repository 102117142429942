import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AgmCoreModule } from "@agm/core";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ComponentLibraryModule } from "../component-library/component-library.module";
import { HttpClientModule } from "@angular/common/http";
import { httpInterceptorProviders } from "./interceptors";
import { AuthGuard } from "./services/auth.guard";
import { ExternalUserGuard } from "./services/external-user.guard";
/* NgRx */
import { ActionReducer, MetaReducer, INIT, StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { appReducers } from "src/app/store/reducers/app.reducers";
import * as userActions from "src/app/store/actions/user/user.actions";
import { ServiceWorkerModule } from "@angular/service-worker";
import { GlobalErrorHandlerService } from "./services/global-error-handler.service";
/* VG Player */
import { VgCoreModule } from "@videogular/ngx-videogular/core";
import { VgControlsModule } from "@videogular/ngx-videogular/controls";
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play";
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering";

import { LazyLoadImageModule } from "ng-lazyload-image";
import { UnAuthGuard } from "./services/un-auth.guard";
import {AutoFocusDirective} from './directives/auto-focus.directive';

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action != null && action.type === userActions.actionUserLogout.type) {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearState];

@NgModule({
  declarations: [AppComponent, AutoFocusDirective],
  entryComponents: [],
  imports: [
    BrowserModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    HttpClientModule,
    IonicModule.forRoot({
      animated: false,
    }),
    AppRoutingModule,
    ComponentLibraryModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.MAPS_KEY,
    }),
    EffectsModule.forRoot([]),
    StoreModule.forRoot(appReducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    LazyLoadImageModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    httpInterceptorProviders,
    AuthGuard,
    UnAuthGuard,
    ExternalUserGuard,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
