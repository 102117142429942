import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';
import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';

@Injectable({ providedIn: 'root' })
export class RentahausToastService {
  constructor(public toastController: ToastController) { }

  public async showToast(
    type: 'warning' | 'success' | 'error' = "success",
    header?: string,
    message?: string,
    duration = 5000,
  ) {
    const options = {
      position: 'top',
      cssClass: type,
      header: capitalizeFirstLetter(type),
      message: 'Your inventory have been saved.',
      duration,
      showCloseButton: true
    } as ToastOptions;

    if (header) {
      options['header'] = header;
    }

    if (message) {
      options.message = message;
    }

    const toast = await this.toastController.create(options);
    toast.present();
  }
}
