import { initialPropertyState, IPropertyState } from '../../state/property/property.state';
import { createReducer, on, Action } from '@ngrx/store';
import * as propertyActions from '../../actions/property/property.actions';

const reducer = createReducer(
  initialPropertyState,
  on(propertyActions.actionSetPropertyAddressForm, (state, {propertyForm})=>({
      ...state,
      propertyForm: propertyForm
  })),
  on(propertyActions.actionSetPropertyRentalType, (state, {rentalType})=>({
    ...state,
    propertyForm: { ...state.propertyForm, rental_type: rentalType }
  })),
  on(propertyActions.actionSetPropertyType, (state, {propertyType})=>({
    ...state,
    propertyForm: { ...state.propertyForm, property_type: propertyType }
  })),
  on(propertyActions.actionSetProperty, (state, { property })=>({
    ...state,
    property: property
  })),
  on(propertyActions.actionSetPropertyTypes, (state, {propertyTypes})=>({
    ...state,
    propertyTypes: propertyTypes
  })),
  on(propertyActions.actionSetPropertyId, (state, {propertyId})=>({
    ...state,
    propertyId: propertyId
  })),
  on(propertyActions.actionSetAvailabilityStatus, (state, {key, value})=>({
    ...state,
    property: {...state.property, [key]: value }
  })),
  on(propertyActions.actionPropertyResolverCall, (state, {propertyResolverCall})=>({
    ...state,
    propertyResolverCall: propertyResolverCall
  })),
  on(propertyActions.actionClearPropertyState, () =>({ 
    ...initialPropertyState
  }))
)

export function propertyReducers(state: IPropertyState | undefined, action: Action) {
  return reducer(state, action);
}
