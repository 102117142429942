import { createReducer, on, Action } from '@ngrx/store';
import * as userActions  from '../../actions/user/user.actions';
import { initialUserState, IUserState } from '../../state/user/user.state';

const reducer = createReducer(
    initialUserState,
    on(userActions.actionLogin, (state, { user }) => ({
        ...state,
        user
    })),
    on(userActions.actionSetUserToken, (state, { token }) => ({
        ...state,
        token
    })),
    on(userActions.actionSetExternalUserToken, (state, { shared_token }) => ({
        ...state,
        shared_token
    })),
    on(userActions.actionSetIsExternalUser, (state, { is_external_user }) => ({
        ...state,
        is_external_user
    })),
    on(userActions.actionSetIsApprover, (state, { is_approver }) => ({
        ...state,
        is_approver
    })),
    on(userActions.actionSetUserRole, (state, { role }) => ({
        ...state,
        role
    })),
    on(userActions.actionUserLogout, (state) => ({
        ...initialUserState
    }))
);

export function userReducers(state: IUserState | undefined, action: Action) {
    return reducer(state, action);
}
