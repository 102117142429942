import { GlobalService } from 'src/app/services/global.service';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Capacitor, Plugins } from '@capacitor/core';
import { UserService } from './services/user.service';
import { HttpConfigInterceptor } from './interceptors/http.interceptor';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private networkStatus = false;
  private networkStatus$: Subscription = Subscription.EMPTY;

  constructor(
    private platform: Platform,
    private userService: UserService,
    private interceptor: HttpConfigInterceptor,
    private globalService: GlobalService
  ) {
    this.initializeApp();
  }

  ngOnInit(): void {
    this.pageRefreshORCloseTab();
    this.checkNetworkStatus();
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable('SplashScreen')) {
        Plugins.SplashScreen.hide();
        this.setUserState();
        this.globalService.deletePendingStatus();
      }
    });
  }

  private setUserState(): void {
    this.userService.userStore(
      this.getUser(),
      this.getToken(),
      this.getSharedToken()
    );
  }

  private getUser(): any {
    return JSON.parse(sessionStorage.getItem('user')) || null;
  }

  private getToken(): any {
    return sessionStorage.getItem('token') || null;
  }

  private getSharedToken(): any {
    return sessionStorage.getItem('shared_token') || null;
  }

  private checkNetworkStatus(): void {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this.networkStatus = status;
        if (this.networkStatus) {
          this.interceptor.sync();
        }
      });
  }

  public pageRefreshORCloseTab(): void {
    const self = this;
    window.onbeforeunload = (e) => {
      if (self.globalService.getPendingStatus) {
        e = e || window.event;
        // For IE and Firefox prior to version 4
        if (e) {
          e.returnValue = 'Sure?';
        }

        // For Safari
        return 'Sure?';
      }
    };
  }
}
