import { IMeterReadingsEvent, IMeterType, IReadingsToBePreserved } from 'src/app/interfaces/meter-reading.interface';

export interface IReadingsState {
    readings: IMeterReadingsEvent,
    readingTypes: IMeterType[],
    readingsToBeSaved: [],
    readingsToBePreserved: IReadingsToBePreserved,
    isInspected: boolean
}

export const initialReadingsState: IReadingsState = {
    readings: null,
    readingTypes: [],
    readingsToBeSaved: [],
    readingsToBePreserved: null,
    isInspected: null
};