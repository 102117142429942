import { IInspectionSummary } from './../../../interfaces/summary.interface';
import { createAction, props } from '@ngrx/store';

export const actionFetchSummary = createAction(
  '[Summary] Fetch Summary',
  props<{propertyId: string}>()
);

export const actionFetchSummaryFail = createAction(
  '[Summary] Fetch Summary Fail',
  props<{error: string}>()
);

export const actionSetSummary = createAction(
  '[Summary] Set Summary',
  props<{inspectionSummary: IInspectionSummary}>()
);

export const actionSaveSummary = createAction(
  '[Summary] Save a Summary',
  props<{inspectionSummary: IInspectionSummary, propertyId: string}>()
);

export const actionSaveSummarySuccess = createAction(
  '[Summary] Save a Summary Success',
  props<{inspectionSummary: IInspectionSummary}>()
);

export const actionSaveSummaryFail = createAction(
  '[Summary] Save a Summary Fail',
  props<{error: string}>()
);


export const actionClearSummaryState = createAction(
  '[Summary] Clear Summary State'
);
