import { createAction, props } from '@ngrx/store';
import { Documents, TenantHandbook, UserManual } from '../../../interfaces/documentation.interface';

export const actionSaveDocument = createAction(
  '[Document] Save a Document',
  props<{document: UserManual | TenantHandbook, docType: string}>()
);

export const actionFetchDocuments = createAction(
  '[Document] Fetch Documents',
  props<{propertyId: string}>()
);

export const actionFetchDocumentsFail = createAction(
  '[Document] Fetch Documents Fail',
  props<{error: string}>()
);

export const actionSetDocument = createAction(
  '[Document] Set Document',
  props<{document: Documents}>()
);

export const actionSaveDocumentSuccess = createAction(
  '[Document] Save a Document Success',
  props<{document: Documents}>()
);

export const actionSaveDocumentFail = createAction(
  '[Document] Save a Document Fail',
  props<{error: string}>()
);

export const actionClearDocumentState = createAction(
  '[Document] Clear Document State'
);

export const actionToInspectDocument = createAction(
  '[Document] To Inspect Document',
  props<{isInspected: boolean}>()
);
