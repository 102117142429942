import {
  AdminUserOverviewAction,
  AdminUserOverviewTypes,
} from "../../actions/admin/userOverview.action";
import {
  AdminUserOverviewState,
  InitialAdminUserOverviewState,
} from "../../state/admin/admin-user-overview";

export function UserOverviewReducer(
  state = InitialAdminUserOverviewState,
  action: AdminUserOverviewAction
): AdminUserOverviewState {
  switch (action.type) {
    case AdminUserOverviewTypes.ActionGetUserByIdSuccess: {
      return {
        ...state,
        userOverviewDetails: action.userOverviewDetails,
      };
    }
    case AdminUserOverviewTypes.ActionGetUserByIdFailure: {
      return {
        ...state,
        userOverviewDetails: action.payload.error,
      };
    }
    default:
      return state;
  }
}
