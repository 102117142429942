import { createAction, props } from '@ngrx/store';
import { SecurityType } from '../../../interfaces/security.interface';

export const actionLoadSecurityType = createAction(
  '[SecurityType] Load Security Type',
);

export const actionLoadSecurityTypeSuccess = createAction(
  '[SecurityType] Load Security Type Success',
  props<{ securityType: SecurityType[] }>()
);
 
export const actionLoadSecurityTypeFail = createAction(
  '[SecurityType] Load Security Type Fail',
  props<{ error: string }>()
);