import { createSelector } from '@ngrx/store';
import { IAppState } from '../../state/app.state';
import { IUserState } from '../../state/user/user.state';


export const selectUser = (state: IAppState) => state.user;


export const selectCurrentUser = createSelector(
  selectUser,
  (state: IUserState) => state.user
);

export const selectIsExternalUser = createSelector(
  selectUser,
  (state: IUserState) => state.is_external_user
);

export const selectIsApprover = createSelector(
  selectUser,
  (state: IUserState) => state.is_approver
);

export const selectRole = createSelector(
  selectUser,
  (state: IUserState) => state.role
);
