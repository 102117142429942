import { createSelector } from '@ngrx/store';
import { IAppState } from '../../state/app.state';
import { IPropertyState } from '../../state/property/property.state';

export const selectProperty = (state: IAppState) => state.property;

export const selectPropertyCreateForm = createSelector(
  selectProperty,
  (state: IPropertyState) => state.propertyForm
);

export const selectPropertyRentalType = createSelector(
  selectProperty,
  (state: IPropertyState) => state.propertyForm ? state.propertyForm.rental_type : null
);

export const selectPropertyType = createSelector(
  selectProperty,
  (state: IPropertyState) => state.propertyForm ? state.propertyForm.property_type : null
);

export const selectCurrentProperty = createSelector(
  selectProperty,
  (state: IPropertyState) => state.property
);

export const selectPropertyId = createSelector(
  selectProperty,
  (state: IPropertyState) => state.propertyId
);

export const selectPropertyTypes = createSelector(
  selectProperty,
  (state: IPropertyState) => state.propertyTypes
);

export const selectPropertyResolverCall = createSelector(
  selectProperty,
  (state: IPropertyState) => state.propertyResolverCall
);
export const selectPropertyTermAndCondition = createSelector(
  selectProperty,
  (state: IPropertyState) => state.property.hasTermAndCondition
);