import { createAction, props } from '@ngrx/store';
import { ICurrentInspection, IInspectionEvent, IInspectionApprovalStatus, IInspectionStatus } from '../../../interfaces/inspection.interface';

  export const actionSetInspectionEvent = createAction(
    '[Inspection] Set Inspection Type Event',
    props<{inspectionEvents: IInspectionEvent[]}>()
  );

  export const actionLoadInspectionEvent = createAction(
    '[Inspection] Load Inspection Event',
    props<{propertyId: string}>()
  );

  export const actionLoadInspectionEventSuccess = createAction(
    '[Inspection] Load Inspection Event Success',
    props<{inspectionEvents: IInspectionEvent[]}>()
  );

  export const actionLoadInspectionEventFail = createAction(
    '[Inspection] Load Inspection Event Fail',
    props<{error: string}>()
  );

  export const actionSetInspectionEventsExist = createAction(
    '[Inspection] Set Inspection Events Exist',
    props<{ isInspectionEventsExist: boolean }>()
  );

  export const actionSetCompletedInspectionEventsExist = createAction(
    '[Inspection] Set Completed Inspection Events Exist',
    props<{ isCompletedInspectionEventsExist: boolean }>()
  );

  export const actionSetInspectionMode = createAction(
    '[Inspection] Set Inspection Mode',
    props<{ isInspectionMode: boolean }>()
  );

  export const actionSetInspectionEventView = createAction(
    '[Inspection] Set Inspection Event View',
    props<{ isViewInspection: boolean }>()
  );


  // Inspection Mode
  export const actionFetchCurrentInspection = createAction(
    '[Inspection] Fetch Current Inspection',
    props<{propertyId: string}>()
  );

  // INSPECTION APPROVAL STATUS
  export const actionFetchInspectionApprovalStatus = createAction(
    '[Inspection] Fetch Inspection Approval Status',
    props<{propertyId: string, inspectionId: string, isCompletedEvent: boolean}>()
  );
  export const actionFetchInspectionApprovalStatusSuccess = createAction(
    '[Inspection] Fetch Inspection Approval Status Success',
    props<{approvalStatus: IInspectionApprovalStatus}>()
  );
  export const actionFetchInspectionApprovalStatusFail = createAction(
    '[Inspection] Fetch Inspection Approval Status Fail',
    props<{error: string}>()
  );

  // SET INSPECTION APPROVER
  export const actionSetInspectionApprover = createAction(
    '[Inspection] Set Inspection Approver',
    props<{approver: IInspectionStatus}>()
  );

  export const actionFetchCurrentInspectionSuccess = createAction(
    '[Inspection] Fetch Current Inspection Success',
    props<{inspectionData: ICurrentInspection}>()
  );

  export const actionFetchInspectionFail = createAction(
    '[Inspection] Fetch Current Inspection Fail',
    props<{error: string}>()
  );
  
  // get inspection for external user / event view
  export const actionFetchInspection = createAction(
    '[Inspection] Fetch Inspection',
    props<{propertyId: string, inspectionId: string, shared_token: string}>()
  );

  // Set Inspection 
  export const actionSetInspection = createAction(
    '[Inspection] Set Inspection',
    props<{inspectionData: ICurrentInspection | null}>()
  );
  
  // PerformInspection
  export const actionPerformInspection = createAction(
    '[Inspection] Perform Inspection',
    props<{propertyId: string, eventId: string}>()
  );

  export const actionPerformInspectionSuccess = createAction(
    '[Inspection] Perform Inspection Success',
    props<{inspectionData: ICurrentInspection}>()
  );

  export const actionPerformInspectionFail = createAction(
    '[Inspection] Perform Inspection Fail',
    props<{error: string}>()
  );

  export const actionExitInspection = createAction(
    '[Inspection] Exit Inspection',
    props<{propertyId: string, inspectionId: string}>()
  );

  export const actionExitInspectionSuccess = createAction(
    '[Inspection] Exit Inspection Success',
    props<{success: boolean}>()
  );

  export const actionExitInspectionFail = createAction(
    '[Inspection] Exit Inspection Fail',
    props<{error: string}>()
  );

  export const actionClearInspectionState = createAction(
    '[Inspection] Clear Inspection State'
  );

  export const actionMutateInspectionState = createAction(
    '[Inspection] Mutate Inspection Property State',
    props<{propertyId: string, data: {
      inspection_id: string,
      state: string,
      key: string
      value: boolean,
      token: string
      }
    }>()
  );
  
  export const actionMutateInspectionPropStateSuccess = createAction(
    '[Inspection] Mutate Inspection Property State Success',
    props<{key:string, value: boolean}>()
  );
  
  export const actionMutateInspectionStateSuccess = createAction(
    '[Inspection] Mutate Inspection  State Success',
    props<{key: string, value: boolean}>()
  );
