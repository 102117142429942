import { createAction, props } from '@ngrx/store';
import { DocumentationType } from "../../../interfaces/documentation.interface";

  export const actionSetDocumentsType = createAction(
    '[DocumentsType] Set Documents Type',
    props<{docsTypes: DocumentationType[]}>()
  );

  export const actionLoadDocumentsType = createAction(
    '[DocumentsType] Load Documents Type'
  );

  export const actionLoadDocumentsTypeSuccess = createAction(
    '[DocumentsType] Load Documents Type Success',
    props<{docsTypes: DocumentationType[]}>()
  );

  export const actionLoadDocumentsTypeFail = createAction(
    'Load Documents Type Fail',
    props<{error: string}>()
  );