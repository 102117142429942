import { createAction, props } from '@ngrx/store';
import { AdditionalInfo, CouncilTax, FurnishedState, Parking, PetPolicy, SmokingPolicy } from '../../../interfaces/additional-info.interface';


export const actionSaveAdditionalInfo = createAction(
  '[AdditionalInfo] Save a AdditionalInfo',
  props<{additionalInfo: FurnishedState | PetPolicy | SmokingPolicy | CouncilTax | Parking, addInfoType: string}>()
);


export const actionSaveAdditionalInfoSuccess = createAction(
  '[AdditionalInfo] Save a AdditionalInfo Success',
  props<{additionalInfo:AdditionalInfo}>()
);

export const actionFetchAdditionalInfo = createAction(
  '[AdditionalInfo] Fetch AdditionalInfo',
  props<{propertyId: string}>()
);

export const actionSetAdditionalInfo = createAction(
  '[AdditionalInfo] Set AdditionalInfo',
  props<{additionalInfo:AdditionalInfo}>()
);

export const actionSaveAdditionalInfoFail = createAction(
  'Save a AdditionalInfo Fail',
  props<{error:string}>()
);

export const actionClearAdditionalInfoState = createAction(
  '[AdditionalInfo] Clear AdditionalInfo State'
);

export const actionToInspectAdditionalInfo = createAction(
  '[AdditionalInfo] To Inspect AdditionalInfo',
  props<{isInspected: boolean}>()
);