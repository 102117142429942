import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
/* NgRx */
import { Store } from "@ngrx/store";
import * as userActions from "src/app/store/actions/user/user.actions";
import { IAppState } from "src/app/store/state/app.state";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private router: Router, private store: Store<IAppState>) {}

  logOut(toAuth = true): void {
    this.clearUserSession();
    if (toAuth) {
      this.store.dispatch(userActions.actionUserLogout());
      this.router.navigate(["auth"], { replaceUrl: true });
    }
  }

  public userStore(
    user: any,
    token: string,
    sharedoken: string
  ): void {
    this.store.dispatch(userActions.actionLogin({ user }));
    this.store.dispatch(userActions.actionSetUserToken({ token }));
    this.store.dispatch(
      userActions.actionSetExternalUserToken({ shared_token: sharedoken })
    );
    const isExternalUser = user ? user.is_external_user : false;
    const isApprover = user ? user.is_external_user : false;
    this.store.dispatch(
      userActions.actionSetIsExternalUser({ is_external_user: isExternalUser })
    );
    this.store.dispatch(userActions.actionSetIsApprover({ is_approver: isApprover }));
    // SET USER ROLE
    const role: string = user && user.role && user.role[1];
    this.store.dispatch(userActions.actionSetUserRole({ role }));
  }

  public clearUserSession(): void {
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("shared_token");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("first_time_login");
    sessionStorage.removeItem("completed");
  }
}
