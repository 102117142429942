import { createAction, props } from '@ngrx/store';
import { AdditionalInfoType } from '../../../interfaces/additional-info.interface';

  export const actionSetAdditionalInfoType = createAction(
    '[AdditionalInfoType] Set AdditionalInfo Type',
    props<{additionalInfoTypes: AdditionalInfoType[]}>()
  );

  export const actionLoadAdditionalInfoType = createAction(
    '[AdditionalInfoType] Load AdditionalInfo Type'
  );

  export const actionLoadAdditionalInfoTypeSuccess = createAction(
    '[AdditionalInfoType] Load AdditionalInfo Type Success',
    props<{additionalInfoTypes: AdditionalInfoType[]}>()
  );

  export const actionLoadAdditionalInfoTypeFail = createAction(
    '[AdditionalInfoType] Load AdditionalInfo Type Fail',
    props<{error: string}>()
  );