import {
  UserRequestActions,
  UserRequestActionTypes,
} from "../../actions/admin/userRequest.actions";
import { UserRequestState } from "../../state/admin/user-request.state";

export const initialUserRequestState: UserRequestState = {
  userRequest: [],
  userOverview: [],
};

export function userRequestReducer(
  state = initialUserRequestState,
  action: UserRequestActions
): UserRequestState {
  switch (action.type) {
    case UserRequestActionTypes.ActionGetUserRequestSuccess: {
      return {
        ...state,
        userRequest: action.userRequest,
      };
    }
    case UserRequestActionTypes.ActionGetUserRequestFailure: {
      return {
        ...state,
        userRequest: action.err,
      };
    }
    case UserRequestActionTypes.ChangeUserStatusSuccess: {
      return {
        ...state,
        userRequest: state.userRequest.map((userRequest) => {
          if (
            userRequest.email === action.email &&
            action.is_approve === true
          ) {
            userRequest.status = "approved";
          } else if (
            userRequest.email === action.email &&
            action.is_approve === false
          ) {
            userRequest.status = "rejected";
          }
          return userRequest;
        }),
      };
    }
    case UserRequestActionTypes.ApproveAssociatedUser: {
      return {
        ...state,
        userOverview: state.userOverview.map((userOverview) => {
          if (userOverview.id === action.payload.id) {
            userOverview.status = action.payload.status;
          }
          return userOverview;
        }),
      };
    }
    case UserRequestActionTypes.RejectedAssociatedUser: {
      return {
        ...state,
        userOverview: state.userOverview.map((userOverview) => {
          if (userOverview.id === action.payload.id) {
            userOverview.status = action.payload.status;
          }
          return userOverview;
        }),
      };
    }
    default:
      return state;
  }
}
