import { IRoom } from './../../../interfaces/room.interface';
import { createAction, props } from '@ngrx/store';

export const actionCreateInventory = createAction(
  '[Inventory] Create a Inventory',
  props<{propertyId: string, stateId: string, inventory: IRoom[]}>()
);

export const actionCreateInventorySuccess = createAction(
  '[Inventory] Create a Inventory Success',
  props<{inventory: IRoom[]}>()
);

export const actionCreateInventoryFail = createAction(
  '[Inventory] Create a Inventory Fail',
  props<{error: string}>()
);

export const actionSetInventory = createAction(
  '[Inventory] Set Inventory',
  props<{inventory: IRoom[]}>()
);

// Inventory Template
export const actionFetchInventory = createAction(
  '[Inventory] Fetch Inventory Template',
  props<{propertyId: string, stateId: string}>()
);
export const actionFetchTemplate = createAction(
  '[Inventory] Fetch Inventory Template Type',
  props<{propertyId: string }>()
);
export const actionCreateInventoryTemplateSuccess = createAction(
  '[Inventory] Create a Inventory Template Success',
  props<{templateType: string}>()
);

export const actionFetchInventoryFail = createAction(
  '[Inventory] Fetch Inventory Template Fail',
  props<{error: string}>()
);

export const actionSetInventoryTemplate = createAction(
  '[Inventory] Set Inventory Template',
  props<{inventory: IRoom[]}>()
);


export const actionClearInventoryState = createAction(
  '[Inventory] Clear Inventory State'
);
