import { initialSecurityTypeState, ISecurityTypeState } from '../../state/security/securityType.state';
import { createReducer, on, Action } from '@ngrx/store';
import * as securityTypeActions  from '../../actions/security/securityType.actions';

const reducer = createReducer(
    initialSecurityTypeState,
    on(securityTypeActions.actionLoadSecurityTypeSuccess, (state, { securityType })=>({
        ...state,
        securityTypes: securityType
    })),
    on(securityTypeActions.actionLoadSecurityTypeFail, (state, { })=>({
        ...state,
        securityTypes: null
    }))
);

export function securityTypeReducers(state: ISecurityTypeState | undefined, action: Action) {
    return reducer(state, action);
}