import { createSelector } from '@ngrx/store';
import { IAppState } from '../../state/app.state';
import { IInspectionState } from '../../state/inspection/inspection.state';

export const selectInspection = (state: IAppState) => state.inspection;

export const selectInspectionEvents = createSelector(
  selectInspection,
  (state: IInspectionState) => state.inspectionEvents
);

export const selectInspectionEventsExist = createSelector(
  selectInspection,
  (state: IInspectionState) => state.isInspectionEventsExist
);

export const selectCompletedInspectionEventsExist = createSelector(
  selectInspection,
  (state: IInspectionState) => state.isCompletedInspectionEventsExist
);

export const selectInspectionMode = createSelector(
  selectInspection,
  (state: IInspectionState) => state.isInspectionMode
);

export const selectViewInspection = createSelector(
  selectInspection,
  (state: IInspectionState) => state.isViewInspection
);

export const selectInspectionData = createSelector(
  selectInspection,
  (state: IInspectionState) => state.inspectionData
);

export const selectInspectionApprovalStatus = createSelector(
  selectInspection,
  (state: IInspectionState) => state.approvalStatus
);

export const selectInspectionApprover = createSelector(
  selectInspection,
  (state: IInspectionState) => state.approver
);