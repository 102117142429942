import { IRoom } from '../../../interfaces/room.interface';
import { createAction, props } from '@ngrx/store';

export const actionFetchRoomsList = createAction(
  '[Room] Fetch Rooms List',
  props<{stateId:string}>()
);

export const actionFetchRoomsListSuccess = createAction(
  '[Room] Fetch Rooms List Success',
  props<{roomsList: IRoom[]}>()
);

export const actionFetchRoomsListFail = createAction(
  '[Room] Fetch Rooms List Fail',
  props<{error: string}>()
);

