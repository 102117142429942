import { Documents } from '../../../interfaces/documentation.interface';

export interface IDocumentState {
    documents: Documents,
    isInspected: boolean
}

export const initialDocumentState: IDocumentState = {
    documents: null,
    isInspected: null
};