import {
  AdminUserListActions,
  AdminUserListActionTypes,
} from "../../actions/admin/userList.actions";
import {
  AdminUserListState,
  initialAdminUserListState,
} from "../../state/admin/user-list.state";

export function adminUserListReducer(
  state = initialAdminUserListState,
  action: AdminUserListActions
): AdminUserListState {
  switch (action.type) {
    case AdminUserListActionTypes.ActionGetUserSuccess: {
      return {
        ...state,
        userList: action.userList,
      };
    }
    case AdminUserListActionTypes.ActionGetUserFailure: {
      return {
        ...state,
        userList: action.err,
      };
    }
    default:
      return state;
  }
}
