import { createReducer, on, Action } from '@ngrx/store';
import * as eventsActions  from '../../actions/events/events.actions';
import { IEventsState, initialEventsState } from '../../state/events/events.state';

const reducer = createReducer(
    initialEventsState,
    on(eventsActions.actionSetEvents, (state, {events})=>({
        ...state,
        events
    })),
    on(eventsActions.actionLoadEventsSuccess, (state, {events})=>({
        ...state,
        events
    })),
    on(eventsActions.actionLoadEventsFail, (state, {})=>({
        ...state,
        docsTypes: null
    }))

);

export function eventsReducers(state: IEventsState | undefined, action: Action) {
    return reducer(state, action);
}