import { createAction, props } from '@ngrx/store';
import { IProperty } from "src/app/interfaces/rh-property.interface";

export const actionLoadProperties = createAction(
  '[PropertyList] Load Properties'
);

export const actionLoadPropertiesSuccess = createAction(
  '[PropertyList] Load Properties Success',
  props<{properties:IProperty[]}>()
);

export const actionSetProperties = createAction(
  '[PropertyList] Set Properties',
  props<{properties:IProperty[]}>()
);

export const actionLoadPropertiesFail = createAction(
  '[PropertyList] Load Properties Fail',
  props<{error:string}>()
);