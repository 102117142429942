import { Action } from "@ngrx/store";
import {
  AssociatedProperty,
  PropertyList,
} from "src/app/interfaces/property-list.interface";

export enum AdminPropertyListActionTypes {
  ActionGetPropertyList = "[Admin Property List] Save Property List",
  ActionGetPropertySuccess = "[Admin Property List] Save Property Success",
  ActionGetPropertyFailure = "[Admin Property List] Save Property Failure",
  ActionGetActiveProperties = "[Admin Property] Get Active Properties",
  ActionGetActivePropertiesSuccess = "[Admin Property] Get Active Properties Success",
  ActionGetActivePropertiesFailure = "[Admin Property] Get Active Properties Failure",
}
export class ActionGetPropertyList implements Action {
  readonly type = AdminPropertyListActionTypes.ActionGetPropertyList;
}
export class ActionGetPropertySuccess implements Action {
  readonly type = AdminPropertyListActionTypes.ActionGetPropertySuccess;
  constructor(public propertyList: PropertyList[]) {}
}
export class ActionGetPropertyFailure implements Action {
  readonly type = AdminPropertyListActionTypes.ActionGetPropertyFailure;
  constructor(public err: any) {}
}
export class ActionGetActiveProperties implements Action {
  readonly type = AdminPropertyListActionTypes.ActionGetActiveProperties;
}
export class ActionGetActivePropertiesFailure implements Action {
  readonly type = AdminPropertyListActionTypes.ActionGetActivePropertiesFailure;
  constructor(public err: any) {}
}
export class ActionGetActivePropertiesSuccess implements Action {
  readonly type = AdminPropertyListActionTypes.ActionGetActivePropertiesSuccess;
  constructor(public activeProperties: AssociatedProperty[]) {}
}

export type AdminPropertyListActions =
  | ActionGetPropertyList
  | ActionGetPropertySuccess
  | ActionGetPropertyFailure
  | ActionGetActiveProperties
  | ActionGetActivePropertiesSuccess
  | ActionGetActivePropertiesFailure;
