import {
  AdminPropertyListActions,
  AdminPropertyListActionTypes,
} from "../../actions/admin/propertyList.actions";
import {
  AdminPropertyListState,
  initialAdminPropertyListState,
} from "../../state/admin/property-list.state";

export function adminPropertyListReducer(
  state = initialAdminPropertyListState,
  action: AdminPropertyListActions
): AdminPropertyListState {
  switch (action.type) {
    case AdminPropertyListActionTypes.ActionGetPropertySuccess: {
      return {
        ...state,
        propertyList: action.propertyList,
      };
    }
    case AdminPropertyListActionTypes.ActionGetPropertyFailure: {
      return {
        ...state,
        propertyList: action.err,
      };
    }
    case AdminPropertyListActionTypes.ActionGetActivePropertiesSuccess: {
      return {
        ...state,
        activeProperties: action.activeProperties,
      };
    }
    case AdminPropertyListActionTypes.ActionGetActivePropertiesFailure: {
      return {
        ...state,
        activeProperties: action.err,
      };
    }
    default:
      return state;
  }
}
