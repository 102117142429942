import { createAction, props } from '@ngrx/store';
import { IReport } from '../../../interfaces/report.interface';

export const actionLoadReports = createAction(
  '[Reports] Load Reports ',
  props<{ propertyId: string }>()
);

export const actionLoadReportsSuccess = createAction(
  '[Reports] Load Reports success',
  props<{ reports: IReport[] }>()
);
 
export const actionLoadReportsFail = createAction(
  '[Reports] Load Reports Fail',
  props<{ error: string }>()
);

export const actionSetReportsExist = createAction(
  '[Reports] Set Reports Exist',
  props<{ isReportsExist: boolean }>()
);

export const actionClearReportsState = createAction(
  '[Reports] Clear Reports State',
);