export const IMAGE_TYPES = [
  '.png',
  '.jpg',
  '.jpeg',
  '.gif'
];

export const VIDEO_TYPES = [
  '.mp4',
  '.3gp',
  '.avi',
  '.mov',
  '.WMV',
  'video/*,audio/*'
];

export const DOCUMENT_TYPES = [
  '.pdf'
];

export const IMAGE_AND_DOCUMENT_TYPES = [
  ...IMAGE_TYPES,
  ...DOCUMENT_TYPES
];

export const IMAGE_AND_VIDEO_TYPES = [
  ...IMAGE_TYPES,
  ...VIDEO_TYPES
];
