import { IPropertyCreateForm } from '../../../interfaces/rh-property.interface';
import { IProperty } from "src/app/interfaces/rh-property.interface";
export interface IPropertyState {
  propertyForm: IPropertyCreateForm,
  property: IProperty,
  propertyId: string,
  propertyTypes:any,
  propertyResolverCall: boolean
}

export const initialPropertyState: IPropertyState = {
  propertyForm: null,
  property: null,
  propertyId: null,
  propertyTypes: null,
  propertyResolverCall: false
};