import { createAction, props } from '@ngrx/store';
import { IReport } from '../../../interfaces/report.interface';


export const actionCreateReport = createAction(
  '[Report] Create a Report',
  props<{ propertyId: string, report:IReport }>()
);

export const actionCreateReportSuccess = createAction(
  '[Report] Create a Report Success',
  props<{report:IReport}>()
);

export const actionCreateReportFail = createAction(
  '[Report] Create a Report Fail',
  props<{error:string}>()
);

export const actionFetchReport = createAction(
  '[Report] Fetch a Report',
  props<{ propertyId: string, reportId: string}>()
);

export const actionToggleReportStatus = createAction(
  '[Report] Toggle a Report Status',
  props<{ propertyId: string, reportId: string, data: { state: boolean }}>()
);

export const actionSetReport = createAction(
  '[Report] Set Report Info',
  props<{report:IReport}>()
);

export const actionClearReportState = createAction(
  '[Report] Clear Report State',
);

