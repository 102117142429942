
import { createReducer, on, Action } from '@ngrx/store';
import * as safetyActions  from '../../actions/safety/safety.actions';
import { initialSafetyState, ISafetyState } from '../../state/safety/safety.state';

const reducer = createReducer(
    initialSafetyState,
    on(safetyActions.actionSaveSafetySuccess, (state, {safety})=>({
        ...state,
        safety
    })),
    on(safetyActions.actionSetSafety, (state, {safety})=>({
        ...state,
        safety
    })),
    on(safetyActions.actionFetchSafetySuccess, (state, {safety})=>({
        ...state,
        safety
    })),
    on(safetyActions.actionSaveSafetyFail, (state, {})=>({
        ...state
    })),
    on(safetyActions.actionFetchSafetyFail, (state, {})=>({
        ...state,
        safety: null
    })),
    on(safetyActions.actionClearSafetyState, () =>({ 
        ...initialSafetyState
    })),
    on(safetyActions.actionToInspectSafety, (state, {isInspected})=>({
        ...state,
        isInspected
    })),
);

export function safetyReducers(state: ISafetyState | undefined, action: Action) {
    return reducer(state, action);
}

