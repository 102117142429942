import { createReducer, on, Action } from '@ngrx/store';
import * as readingsActions  from '../../actions/meterReadings/readings.actions';
import { initialReadingsState, IReadingsState } from '../../state/meterReadings/readings.state';

const reducer = createReducer(
    initialReadingsState,
    on(readingsActions.actionSetReadingsEvent, (state, {readings})=>({
        ...state,
        readings
    })),
    on(readingsActions.actionLoadReadingsSuccess, (state, {readings})=>({
        ...state,
        readings
    })),
    on(readingsActions.actionLoadReadingsFail, (state, {})=>({
        ...state,
        readings: null
    })),
    on(readingsActions.actionSetReadingTypes, (state, {readingTypes})=>({
        ...state,
        readingTypes
    })),
    on(readingsActions.actionAddReadingToSaveQueue, (state, {readingsToBeSaved})=>({
        ...state,
        readingsToBeSaved
    })),
    on(readingsActions.actionAddReadingToPreserve, (state, {readingsToBePreserved})=>({
        ...state,
        readingsToBePreserved
    })),
    on(readingsActions.actionCreateReading, () =>({
        ...initialReadingsState
    })),
    on(readingsActions.actionClearReadingsSaveState, (state)=>({
        ...state,
        readingsToBePreserved: null,
        readingsToBeSaved: []
    })),
    on(readingsActions.actionToInspectReadings, (state, {isInspected})=>({
        ...state,
        isInspected
    })),
);

export function readingsReducers(state: IReadingsState | undefined, action: Action) {
    return reducer(state, action);
}