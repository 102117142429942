
import { createReducer, on, Action } from '@ngrx/store';
import * as roomActions  from '../../actions/inventory/room.actions';
import { initialRoomState, IRoomState } from '../../state/inventory/room.state';

const reducer = createReducer(
    initialRoomState,
    on(roomActions.actionFetchRoomsListSuccess, (state, {roomsList})=>({
        ...state,
        roomsList
    }))
);

export function roomReducers(state: IRoomState | undefined, action: Action) {
    return reducer(state, action);
}

