import { Action } from "@ngrx/store";
import { UserRequest } from "src/app/interfaces/user-request.interface";

export enum UserRequestActionTypes {
  ActionGetUserRequest = "[Admin] Get User Request",
  ActionGetUserRequestSuccess = "[Admin] Get User Request Success",
  ActionGetUserRequestFailure = "[Admin] Get User Request Failure",
  ChangeUserStatus = "[Admin] Approve User Request",
  ChangeUserStatusSuccess = "[Admin] Change User Status Success",
  ApproveAssociatedUser = "[Admin] Approve Associated User",
  RejectedAssociatedUser = "[Admin] Rejected Associated User",
}

export class ActionGetUserRequest implements Action {
  readonly type = UserRequestActionTypes.ActionGetUserRequest;
}
export class ActionGetUserRequestSuccess implements Action {
  readonly type = UserRequestActionTypes.ActionGetUserRequestSuccess;
  constructor(public userRequest: UserRequest[]) {}
}

export class ActionGetUserRequestFailure implements Action {
  readonly type = UserRequestActionTypes.ActionGetUserRequestFailure;
  constructor(public err: any) {}
}

export class ChangeUserStatus implements Action {
  readonly type = UserRequestActionTypes.ChangeUserStatus;
  constructor(public email: string, public is_approve: boolean) {}
}
export class ChangeUserStatusSuccess implements Action {
  readonly type = UserRequestActionTypes.ChangeUserStatusSuccess;
  constructor(public email: string, public is_approve: boolean) {}
}
export class ApproveAssociatedUser implements Action {
  readonly type = UserRequestActionTypes.ApproveAssociatedUser;
  constructor(public payload: { id: number; status: string }) {}
}
export class RejectedAssociatedUser implements Action {
  readonly type = UserRequestActionTypes.RejectedAssociatedUser;
  constructor(public payload: { id: number; status: string }) {}
}

export type UserRequestActions =
  | ActionGetUserRequest
  | ActionGetUserRequestSuccess
  | ActionGetUserRequestFailure
  | ChangeUserStatus
  | ChangeUserStatusSuccess
  | ApproveAssociatedUser
  | RejectedAssociatedUser;
