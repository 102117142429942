import { createAction, props } from '@ngrx/store';

export const actionFetchItemsList = createAction(
  '[Item] Fetch Items List'
);

export const actionFetchItemsListSuccess = createAction(
  '[Item] Fetch Items List Success',
  props<{itemsList: []}>()
);

export const actionFetchItemsListFail = createAction(
  '[Item] Fetch Items List Fail',
  props<{error: string}>()
);

