import {
  AdminInspectedPropertyDetailsAction,
  AdminInspectedpropertyDetailsType,
} from "../../actions/admin/propertyActivityDetails.actions";
import {
  PropertyReportInitialState,
  PropertyReportState,
} from "../../state/admin/property-report";

export function PropertyDetailReducer(
  state = PropertyReportInitialState,
  action: AdminInspectedPropertyDetailsAction
): PropertyReportState {
  switch (action.type) {
    case AdminInspectedpropertyDetailsType.GetPropertyReportSuccess: {
      return {
        ...state,
        propertyReports: action.propertyReports,
      };
    }
    case AdminInspectedpropertyDetailsType.ActionGetInspectedPropertiesSuccess: {
      return {
        ...state,
        inspectedProperties: action.inspectedProperties,
      };
    }
    default:
      return state;
  }
}
