import { createAction, props } from '@ngrx/store';
import { ISafety } from 'src/app/interfaces/safety.interface';

export const actionSaveSafety = createAction(
  '[Safety] Save a Safety',
  props<{safety: FormData, safetyTypeId: string}>()
);

export const actionFetchSafety = createAction(
  '[Safety] Fetch Safety',
  props<{propertyId: string}>()
);

export const actionFetchSafetySuccess = createAction(
  '[Safety] Fetch Safety Success',
  props<{safety: ISafety}>()
);

export const actionFetchSafetyFail = createAction(
  '[Safety] Fetch Safety Fail',
  props<{error: string}>()
);

export const actionSetSafety = createAction(
  '[Safety] Set Safety',
  props<{safety: ISafety}>()
);

export const actionSaveSafetySuccess = createAction(
  '[Safety] Save a Safety Success',
  props<{safety: ISafety}>()
);

export const actionSaveSafetyFail = createAction(
  '[Safety] Save a Safety Fail',
  props<{error: string}>()
);

export const actionClearSafetyState = createAction(
  '[Safety] Clear Safety State',
);

export const actionToInspectSafety = createAction(
  '[Safety] To Inspect Safety',
  props<{isInspected: boolean}>()
);