import { UserRequest } from "src/app/interfaces/user-request.interface";

export interface PropertyReportState {
  propertyReports: any;
  inspectedProperties: UserRequest[];
}

export const PropertyReportInitialState: PropertyReportState = {
  propertyReports: {},
  inspectedProperties: [],
};
