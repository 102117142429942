import { ICurrentInspection, IInspectionEvent, IInspectionApprovalStatus, IInspectionStatus } from "../../../interfaces/inspection.interface";

export interface IInspectionState {
    inspectionEvents: IInspectionEvent[],
    isInspectionEventsExist: boolean,
    isCompletedInspectionEventsExist: boolean,
    isInspectionMode: boolean,
    isViewInspection: boolean,
    inspectionData: ICurrentInspection,
    approvalStatus: IInspectionApprovalStatus,
    approver: IInspectionStatus
}

export const initialInspectionState: IInspectionState = {
    inspectionEvents: [],
    isInspectionEventsExist: false,
    isCompletedInspectionEventsExist: false,
    isInspectionMode: false,
    isViewInspection: false,
    inspectionData: null,
    approvalStatus: null,
    approver: null
};