import {
  AssociatedProperty,
  PropertyList,
} from "src/app/interfaces/property-list.interface";

export interface AdminPropertyListState {
  propertyList: PropertyList[];
  inspectedPropertyList: PropertyList[];
  associatedProperty: AssociatedProperty[];
  propertyActivity: AssociatedProperty[];
  activeProperties: AssociatedProperty[];
}

export const initialAdminPropertyListState: AdminPropertyListState = {
  propertyList: [],
  associatedProperty: [],
  propertyActivity: [],
  inspectedPropertyList: [],
  activeProperties: [],
};
