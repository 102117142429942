import { createAction, props } from '@ngrx/store';
import { ISafetyType } from '../../../interfaces/safety.interface';

  export const actionSetSafetyType = createAction(
    '[SafetyType] Set Safety Type',
    props<{safetyTypes: ISafetyType[]}>()
  );

  export const actionLoadSafetyType = createAction(
    '[SafetyType] Load Safety Type'
  );

  export const actionLoadSafetyTypeSuccess = createAction(
    '[SafetyType] Load Safety Type Success',
    props<{safetyTypes: ISafetyType[]}>()
  );

  export const actionLoadSafetyTypeFail = createAction(
    '[SafetyType] Load Safety Type Fail',
    props<{error: string}>()
  );