import { Injectable } from "@angular/core";
import {
  Route,
  CanLoad,
  UrlSegment,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { SignupService } from "../pages/auth/signup/signup.service";

@Injectable({
  providedIn: "root",
})
export class UnAuthGuard implements CanLoad {
  public constructor(
    private signUpService: SignupService,
    private router: Router
  ) {}

  public canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.signUpService.isUserAuthenticated) {
        this.router.navigate(["/property"]);
      }
      return !this.signUpService.isUserAuthenticated;
  }
}
