import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { IRegisterInterestForm } from "../../../interfaces/auth.interface";
import {
  REGISTER_ENDPOINT,
  EMAIL_VERIFICATION_ENDPOINT,
  EMAIL_LOGIN_ENDPOINT,
  REQUEST_RESET_PASSWORD_ENDPOINT,
  CONFIRM_RESET_PASSWORD_ENDPOINT,
} from "../../../endpoints/api.endpoints";

@Injectable({
  providedIn: "root",
})
export class SignupService {
  constructor(private httpClient: HttpClient) {}

  get isUserAuthenticated(): boolean {
    const token = sessionStorage.getItem("token");
    const shared_token = sessionStorage.getItem("shared_token");
    if (token || shared_token) {
      return true;
    } else {
      return false;
    }
  }

  public registerUser(formData: IRegisterInterestForm): Observable<any> {
    return this.httpClient.post(environment.url + REGISTER_ENDPOINT, formData);
  }

  public isAdminUser(): boolean {
    const role = sessionStorage.getItem("role");
    if (role === "ROLE_ADMIN") {
      return true;
    } else {
      return false;
    }
  }

  public verifyEmail(formData: {
    email: string;
    code: string;
  }): Observable<any> {
    formData.code = (formData.code || "").toUpperCase();
    return this.httpClient.post(
      environment.url + EMAIL_VERIFICATION_ENDPOINT,
      formData
    );
  }

  public login(formData): Observable<any> {
    return this.httpClient.post(
      environment.url + EMAIL_LOGIN_ENDPOINT,
      formData
    );
  }

  public forgotPasswordRequest(formData): Observable<any> {
    return this.httpClient.post(
      environment.url + REQUEST_RESET_PASSWORD_ENDPOINT,
      formData
    );
  }

  public renewPassword(formData): Observable<any> {
    return this.httpClient.post(
      environment.url + CONFIRM_RESET_PASSWORD_ENDPOINT,
      formData
    );
  }
}
