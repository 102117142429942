import { IPreserveReading } from './../../../interfaces/meter-reading.interface';
import { IReadingsToBePreserved, IMeterReadingsEvent, IMeterType } from 'src/app/interfaces/meter-reading.interface';
import { createAction, props } from '@ngrx/store';

export const actionCreateReading = createAction(
  '[Readings] Create Reading',
  props<{readings: any[]}>()
);

export const actionCreateReadingSuccess = createAction(
  '[Readings] Create Reading Success',
  props<{readings: IMeterReadingsEvent}>()
);

export const actionCreateReadingFail = createAction(
  '[Readings] Create Reading Fail',
  props<{error: string}>()
);

export const actionSetReadingsEvent = createAction(
  '[Readings] Set Readings',
  props<{readings: IMeterReadingsEvent}>()
);

export const actionLoadReadings = createAction(
  '[Readings] Load Readings'
);

export const actionLoadReadingsSuccess = createAction(
  '[Readings] Load Readings Success',
  props<{readings: IMeterReadingsEvent}>()
);

export const actionLoadReadingsFail = createAction(
  'Load Readings Success Fail',
  props<{error: string}>()
);

export const actionLoadReadingTypes = createAction(
  '[Readings] Load Reading Types'
);

export const actionSetReadingTypes = createAction(
  '[Readings] Set Reading Types',
  props<{readingTypes: IMeterType[]}>()
);


export const actionAddReadingToSaveQueue = createAction(
  '[Readings] Add Reading To Save Queue',
  props<{readingsToBeSaved: any}>()
);

export const actionGetReadingsFromSaveQueue = createAction(
  '[Readings] Get Readings From Save Queue',
);

export const actionAddReadingToPreserve = createAction(
  '[Readings] Add Reading To Preserve',
  props<{readingsToBePreserved: IReadingsToBePreserved}>()
);

export const actionGetReadingsFromPreserve = createAction(
  '[Readings] Get Readings From Preserve',
);

export const actionClearReadingsState = createAction(
  '[Readings] Clear Readings State'
);

export const actionClearReadingsSaveState = createAction(
  '[Readings] Clear Reading Save State'
);

export const actionToInspectReadings = createAction(
  '[Readings] To Inspect Readings',
  props<{isInspected: boolean}>()
);
