import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class ExternalUserGuard implements CanActivate {

    public constructor(
        private router: Router,
        public globalService: GlobalService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state:RouterStateSnapshot):
     Observable<boolean> | Promise<boolean> | boolean {
        if (this.globalService.isExternalUser) {
            this.router.navigate(['auth']);
        }
        return this.globalService.isExternalUser;
    }
}
