import {GlobalService} from '../services/global.service';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {concat, EMPTY, Observable, throwError, TimeoutError} from 'rxjs';
import {catchError, map, share} from 'rxjs/operators';
import {SyncTask} from '../classes/sync-task.class';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/state/app.state'
import * as propertyListActions from 'src/app/store/actions/property/propertiesList.actions';

const STORAGE_KEY = 'syncTasks';

@Injectable({
  providedIn: 'root',
})
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private httpClient: HttpClient,
    private store: Store<IAppState>,
    private globalService: GlobalService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token: string = sessionStorage.getItem('token');
    const method = request.method.toLowerCase();
    const payload = request.body;
    const params = request.params;
    let payloadObj = {};
    let requestUrl = null;
    let action = null;
    let actionFrom = null;

    if (method === 'post' || method === 'delete') {
      requestUrl = request.url;
      if (payload instanceof FormData) {
        payload.forEach((value, key) => {
          if(key == 'store_action') {
            action = value;
          }
          if(key == 'store_from_action') {
            actionFrom = value;
          }
          payloadObj[key] = value;
        } );
      } else {
        payloadObj = request.body || {};
        action = payloadObj['store_action'];
        actionFrom = payloadObj['store_from_action'];
      }
    }

    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
      });
    }

    if (
      !request.headers.has('Content-Type') &&
      request.url.includes('https://maps.googleapis')
    ) {
      return next.handle(request);
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => event),
      catchError((error: HttpErrorResponse) => {
        const method = request.method.toLowerCase();
        if (method === 'post' || method === 'delete') {
          return this.handleError(error, requestUrl, method, payloadObj, params, action, actionFrom);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private handleError<T>(
    err: HttpErrorResponse,
    url: string,
    method: string,
    payload: T,
    params: HttpParams,
    action: string,
    actionFrom: string
  ): Observable<any> {
    if (this.offlineOrBadConnection(err)) {
      // A client-side or network error occurred. Handle it accordingly.
      this.addOrUpdateSyncTask<T>(url, method, payload, params, action, actionFrom);

      return throwError(err);
    } else {
      console.log('A backend error occurred.', err);
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      return throwError(err);
    }
  }

  private addOrUpdateSyncTask<T>(
    url: string,
    method: string,
    payload: T,
    params: HttpParams,
    action: string,
    actionFrom: string
  ): void {
    const tasks = this.getExistingSyncTasks();

    const syncTask = new SyncTask(url, method, payload, params.toString(), action, actionFrom);
    tasks.push(syncTask);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(tasks));
    this.globalService.errorToaster('System down or network issue, but your request is ' +
        'preserved, data will sync with server when system back.');
  }

  private getExistingSyncTasks(): any {
    const serializedTasks = localStorage.getItem(STORAGE_KEY);

    return serializedTasks ? JSON.parse(serializedTasks) : [];
  }

  private offlineOrBadConnection(err: HttpErrorResponse): boolean {
    return (
      err instanceof TimeoutError ||
      err.error instanceof ErrorEvent ||
      !window.navigator.onLine
    );
  }

  sync(): Observable<any> {
    if (window.navigator.onLine) {
      const token: string = sessionStorage.getItem('token');
      const syncTasks = this.getExistingSyncTasks();
      if (token && syncTasks && syncTasks.length > 0) {
        this.globalService.successToaster('Offline data syncing...');
        const requests: Observable<any>[] = [];

        syncTasks.forEach((task: SyncTask<any>) => {
          const params = {
            params: new HttpParams({ fromString: task.params }),
          };
          const obs$ = this.httpClient
            [task.method](task.url, task.body, params)
            .pipe(map((_) => task));

          requests.push(obs$);
        });

        const all$ = concat(...requests).pipe(share());

        all$.subscribe((task) => {
          const index = syncTasks.findIndex(
            (t) => JSON.stringify(t) === JSON.stringify(task)
          );
          if(task.action === "actionLoadProperties") {
            this.store.dispatch(propertyListActions.actionLoadProperties());
          }
          syncTasks.splice(index, 1);
          const items = Object.assign([], syncTasks);
          localStorage.setItem(STORAGE_KEY, JSON.stringify(items));
        });

        return all$;
      } else {
        return EMPTY;
        // this.globalService.errorToaster("Offline data is already synced!");
      }
    } else {
      this.globalService.errorToaster(
        'Please check your internet connection OR try again!'
      );
    }
  }
}
