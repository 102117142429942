import { AdditionalInfo } from '../../../interfaces/additional-info.interface';

export interface IAdditionalInfoState {
    additionalInfo: AdditionalInfo,
    isInspected: boolean
}

export const initialAdditionalInfoState: IAdditionalInfoState = {
    additionalInfo: null,
    isInspected: null
};