
import { createReducer, on, Action } from '@ngrx/store';
import * as itemActions  from '../../actions/inventory/item.actions';
import { IItemState, initialItemState } from '../../state/inventory/item.state';

const reducer = createReducer(
    initialItemState,
    on(itemActions.actionFetchItemsListSuccess, (state, {itemsList})=>({
        ...state,
        itemsList
    }))
);

export function itemReducers(state: IItemState | undefined, action: Action) {
    return reducer(state, action);
}

