import { createReducer, on, Action } from '@ngrx/store';
import * as safetyTypesActions  from '../../actions/safety/safetyTypes.actions';
import { initialSafetyTypeState, ISafetyTypeState } from '../../state/safety/safetyTypes.state';

const reducer = createReducer(
    initialSafetyTypeState,
    on(safetyTypesActions.actionSetSafetyType, (state, {safetyTypes})=>({
        ...state,
        safetyTypes
    })),
    on(safetyTypesActions.actionLoadSafetyTypeSuccess, (state, {safetyTypes})=>({
        ...state,
        safetyTypes
    })),
    on(safetyTypesActions.actionLoadSafetyTypeFail, (state, {})=>({
        ...state,
        safetyTypes: null
    }))
);

export function safetyTypesReducers(state: ISafetyTypeState | undefined, action: Action) {
    return reducer(state, action);
}