import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    constructor(protected httpClient: HttpClient) {}
    public cleanEmail(obj) {
        for (var prop in obj) {
            if (obj[prop].email === null || obj[prop].email === undefined) {
                delete obj[prop];
            }
        }
        return this.clean(obj);
    }

    public clean(obj) {
        return obj.filter(function (el) {
            return el != null;
        })
    }
}
