import { IAdditionalInfoState, initialAdditionalInfoState } from "../../state/additionalInfo/additionalInfo.state";
import * as additionalInfoActions  from '../../actions/additionalInfo/additionalInfo.actions';
import { createReducer, on, Action } from "@ngrx/store";

const reducer = createReducer(
    initialAdditionalInfoState,
    on(additionalInfoActions.actionSaveAdditionalInfoSuccess, (state, {additionalInfo})=>({
        ...state,
        additionalInfo: additionalInfo
    })),
    on(additionalInfoActions.actionSetAdditionalInfo, (state, {additionalInfo})=>({
        ...state,
        additionalInfo: additionalInfo
    })),
    on(additionalInfoActions.actionSaveAdditionalInfoFail, (state, {})=>({
        ...state
    })),
    on(additionalInfoActions.actionClearAdditionalInfoState, () =>({
        ...initialAdditionalInfoState
    })),
    on(additionalInfoActions.actionToInspectAdditionalInfo, (state, {isInspected})=>({
        ...state,
        isInspected
    })),
);

export function additionalInfoReducers(state: IAdditionalInfoState | undefined, action: Action) {
    return reducer(state, action);
}