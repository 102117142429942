export interface IUserState {
    user: any;
    token: string;
    shared_token: string;
    is_external_user: boolean;
    is_approver: boolean;
    role: string;
}

export const initialUserState: IUserState = {
    user: null,
    token: null,
    shared_token: null,
    is_external_user: null,
    is_approver: null,
    role: null
};
