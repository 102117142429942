
import { createReducer, on, Action } from '@ngrx/store';
import * as termsActions  from '../../actions/terms/terms.actions';
import { initialTermsState, ITermsState } from '../../state/terms/terms.state';


const reducer = createReducer(
    initialTermsState,
    on(termsActions.actionSaveTermsSuccess, (state, {terms})=>({
        ...state,
        terms
    })),
    on(termsActions.actionSaveTermsFail, (state, {})=>({
        ...state
    })),
    on(termsActions.actionSetTerms, (state, {terms})=>({
        ...state,
        terms
    })),
    on(termsActions.actionFetchTermsFail, (state, {})=>({
        ...state,
        terms: null
    })),
    on(termsActions.actionClearTermsSate, ()=>({
        ...initialTermsState
    }))
);

export function termsReducers(state: ITermsState | undefined, action: Action) {
    return reducer(state, action);
}

