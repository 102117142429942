
import { createReducer, on, Action } from '@ngrx/store';
import * as summaryActions  from '../../actions/summary/summary.actions';
import { initialSummaryState, ISummaryState } from '../../state/summary/summary.state';


const reducer = createReducer(
    initialSummaryState,
    on(summaryActions.actionSaveSummarySuccess, (state, {inspectionSummary})=>({
        ...state,
        inspectionSummary
    })),
    on(summaryActions.actionSaveSummaryFail, (state, {})=>({
        ...state
    })),
    on(summaryActions.actionSetSummary, (state, {inspectionSummary})=>({
        ...state,
        inspectionSummary
    })),
    on(summaryActions.actionFetchSummaryFail, (state, {})=>({
        ...state,
        inspectionSummary: null
    })),
    on(summaryActions.actionClearSummaryState, ()=>({
        ...initialSummaryState
    }))
);

export function summaryReducers(state: ISummaryState | undefined, action: Action) {
    return reducer(state, action);
}

