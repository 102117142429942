import { IMeterReadingsEvent } from '../../../interfaces/meter-reading.interface';
import { createAction, props } from '@ngrx/store';


export const actionLoadMeterReadingsEvents = createAction(
  '[MeterReadingsEvent] Load Meter Readings Events',
  props<{propertyId: string}>()
);

export const actionSetMeterReadingsEvent = createAction(
  '[MeterReadingsEvent] Set Meter Readings Event',
  props<{meterReadingsEvents: IMeterReadingsEvent}>()
);

export const actionSetSortedReadingKeys = createAction(
  '[MeterReadingsEvent] Set Sorted Reading Keys',
  props<{sortedReadingKeys: string[]}>()
);

export const actionLoadMeterReadingsEventsFail = createAction(
  '[MeterReadingsEvent] Load Meter Readings Events Fail',
  props<{error: string}>()
);