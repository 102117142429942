import { KeysAndFobs, SecurityAlarm, Security } from 'src/app/interfaces/security.interface';
import { createAction, props } from '@ngrx/store';


export const actionSaveSecurity = createAction(
  '[Security] Save a Security',
  props<{security:KeysAndFobs | SecurityAlarm, securityType: string}>()
);

export const actionSaveSecuritySuccess = createAction(
  '[Security] Save a Security Success',
  props<{security:Security}>()
);

export const actionSaveSecurityFail = createAction(
  '[Security] Save a Security Fail',
  props<{error:string}>()
);

export const actionFetchSecurities = createAction(
  '[Security] Fetch Securities',
  props<{propertyId: string}>()
);

export const actionSetSecurity = createAction(
  '[Security] Set Security Info',
  props<{security:Security}>()
);

export const actionClearSecurityState = createAction(
  '[Security] Clear Security State',
);

export const actionToInspectSecurity = createAction(
  '[Security] To Inspect Security',
  props<{isInspected: boolean}>()
);

