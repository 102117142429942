import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../app/material.module';
import { RhAccountCard } from './account-card/account-card.component';
import { RhOptionCheck } from './option-check/option-check.component';
import { RhDesktopContainer } from './desktop-container/desktop-container.component';
import { RhPageWrapper } from './page-wrapper/page-wrapper.component';

import { RhFlatInformation } from './flat-information/flat-information.component';
import { RhRoomLineItem } from './room-line-item/room-line-item.component';
import { RhRentahausListComponent } from './rentahaus-list/rentahaus-list.component';
import { RhCompletedListItemComponent } from './inventory-list-item/inventory-list-item.component';
import { RhCustomSelectComponent } from './rh-custom-select/rh-custom-select.component';
import { RhLoadingComponent } from './rh-loading/rh-loading.component';

import { RhInputComponent } from './rh-input/rh-input.component';
import { RhPatternDirective } from './rh-input/rh-pattern.directive';
import { RhHeaderComponent } from './rh-header/rh-header.component';
import { RhPropertyType } from './property-type/property-type.component';
import { RhTabsComponent } from './tabs/rh-tabs.component';
import { RhImageUploader } from './media-uploader/media-uploader.component';
import { RhConfirmModalComponent } from './rh-confirm-modal/rh-confirm-modal.component';
import { RhSpinner } from './rh-spinner/rh-spinner.component';
import { RhAddRoomModalComponent } from './rh-add-room-modal/rh-add-room-modal.component';
import { RhAddItemModalComponent } from './rh-add-item-modal/rh-add-item-modal.component';
import { RhDatepickerComponent } from './rh-date-picker/rh-date-picker.component';
import { RhDropDownComponent } from './rh-drop-down/rh-drop-down.component';
import { RhTextareaComponent } from './rh-textarea/rh-textarea.component';
import { EventReportModalComponent } from './event-report-modal/event-report-modal.component';
import { RhQuantityComponent } from './rh-quantity/rh-quantity.component';
import { RhFileMangerComponent } from './rh-media-manger/rh-media-manger.component';
import { RhFilePreviewComponent } from './rh-media-preview/rh-media-preview.component';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { RhSelectInspectionModalComponent } from './rh-select-inspection-modal/rh-select-inspection-modal.component';
import { RhToggleBtnComponent } from './rh-toggle-button/rh-toggle-btn.component';
import { RhReportActionButtonComponent } from './rh-report-action-button/rh-report-action-button.component';
import { RhShareEventComponent } from './rh-share-event/rh-share-event.component';
import { RhInspectionItemNotFoundModalComponent } from './rh-inspection-item-not-found-modal/rh-inspection-item-not-found-modal.component';
import { RhInspectionStatusComponent } from './rh-inspection-status/rh-inspection-status.component';
import { RhIconIndicatorComponent } from './rh-icon-indicator/rh-icon-indicator.component';
import { RhImageHandlerComponent } from './rh-media-handler/rh-media-handler.component';
import { RhOpenCloseReportsComponent } from './rh-open-close-reports/rh-open-close-reports.component';
import { RhVerificationComponent } from './rh-verification/rh-verification.component';
import { RhVerificationConfirmComponent } from './rh-verification-confirm/rh-verification-confirm.component';
import { RhTogglePropertyComponent } from './rh-toggle-property/rh-toggle-property.component';
import { RhPolicyComponent } from './rh-policy/rh-policy.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { RhMediaPlayerComponent } from './rh-media-player/rh-media-player.component';
import {RhPropertyDetailComponent} from './rh-property-detail/rh-property-detail.component';

import {RhPropertyRecordComponent} from './rh-property-record/rh-property-record.component';
import {RhInspectionEventsComponent} from './rh-inspection-events/rh-inspection-events.component';
import {RhAddApprovalButtonComponent} from './hr-add-approval-button/rh-add-approval-button.component';

/* VG Player */
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';

import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RhAddPropertyModalComponent } from './rh-add-property-modal/rh-add-property-modal.component';
import { ClickOutsideDirective } from './rh-drop-down/click-outside.directive';
import {RhViewApprovalStatusComponent} from './rh-view-approval-status/rh-view-approval-status.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    MaterialModule,
    RouterModule,
    PipesModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    LazyLoadImageModule
  ],
  declarations: [
    RhFlatInformation,
    RhAccountCard,
    RhOptionCheck,
    RhDesktopContainer,
    RhPageWrapper,
    RhRoomLineItem,
    RhRentahausListComponent,
    RhCompletedListItemComponent,
    RhCustomSelectComponent,
    RhLoadingComponent,
    RhInputComponent,
    RhPatternDirective,
    RhHeaderComponent,
    RhTabsComponent,
    RhPropertyType,
    RhImageUploader,
    RhFileMangerComponent,
    RhFilePreviewComponent,
    RhConfirmModalComponent,
    RhSpinner,
    RhAddRoomModalComponent,
    RhAddItemModalComponent,
    RhAddPropertyModalComponent,
    RhDatepickerComponent,
    RhDropDownComponent,
    RhTextareaComponent,
    EventReportModalComponent,
    RhQuantityComponent,
    RhSelectInspectionModalComponent,
    RhToggleBtnComponent,
    RhReportActionButtonComponent,
    RhShareEventComponent,
    RhInspectionItemNotFoundModalComponent,
    RhInspectionStatusComponent,
    RhIconIndicatorComponent,
    RhImageHandlerComponent,
    RhOpenCloseReportsComponent,
    RhVerificationComponent,
    RhVerificationConfirmComponent,
    RhTogglePropertyComponent,
    RhPolicyComponent,
    RhMediaPlayerComponent,
    ClickOutsideDirective,
    RhPropertyDetailComponent,
    RhPropertyRecordComponent,
    RhInspectionEventsComponent,
    RhAddApprovalButtonComponent,
    RhViewApprovalStatusComponent
  ],
  exports: [
    RhFlatInformation,
    RhAccountCard,
    RhOptionCheck,
    RhDesktopContainer,
    RhPageWrapper,
    RhRoomLineItem,
    RhRentahausListComponent,
    RhCompletedListItemComponent,
    RhCustomSelectComponent,
    RhLoadingComponent,
    RhInputComponent,
    RhPatternDirective,
    RhHeaderComponent,
    RhTabsComponent,
    RhPropertyType,
    RhImageUploader,
    RhFileMangerComponent,
    RhFilePreviewComponent,
    RhConfirmModalComponent,
    RhSpinner,
    RhAddRoomModalComponent,
    RhAddItemModalComponent,
    RhAddPropertyModalComponent,
    RhDatepickerComponent,
    RhDropDownComponent,
    RhTextareaComponent,
    EventReportModalComponent,
    RhQuantityComponent,
    RhSelectInspectionModalComponent,
    RhToggleBtnComponent,
    RhReportActionButtonComponent,
    RhShareEventComponent,
    RhInspectionItemNotFoundModalComponent,
    RhInspectionStatusComponent,
    RhIconIndicatorComponent,
    RhImageHandlerComponent,
    RhOpenCloseReportsComponent,
    RhVerificationComponent,
    RhVerificationConfirmComponent,
    RhTogglePropertyComponent,
    RhPolicyComponent,
    RhMediaPlayerComponent,
    RhPropertyDetailComponent,
    RhPropertyRecordComponent,
    RhInspectionEventsComponent,
    RhAddApprovalButtonComponent,
    RhViewApprovalStatusComponent
  ],
  providers: [
    InAppBrowser
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class ComponentLibraryModule { }
