import { createReducer, on, Action } from '@ngrx/store';
import * as reportActions  from '../../actions/report/report.actions';
import { IReportState, initialReportState } from '../../state/report/report.state';


const reducer = createReducer(
    initialReportState,
    on(reportActions.actionCreateReportSuccess, (state, { report })=>({
        ...state,
        report: report
    })),
    on(reportActions.actionSetReport, (state, { report })=>({
        ...state,
        report: report
    })),
    on(reportActions.actionCreateReportFail, (state, {})=>({
        ...state
    })),
    on(reportActions.actionClearReportState, () =>({ 
    ...initialReportState
    }))
);

export function reportReducers(state: IReportState | undefined, action: Action) {
    return reducer(state, action);
}