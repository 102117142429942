import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FilterPipe } from "./filter.pipe";
import { SafePipe } from "./safe.pipe";
import { StatusPipe } from "./status.pipe";
import { AppStatusPipe } from "./app-status.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [FilterPipe, SafePipe, StatusPipe, AppStatusPipe],
  exports: [FilterPipe, SafePipe, StatusPipe, AppStatusPipe],
})
export class PipesModule {}
