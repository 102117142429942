import {
  AdminAssociatedPropertyActions,
  AdminAssociatedPropertyTypes,
} from "../../actions/admin/associatedProperties.actions";
import {
  associatedPropertiesInitialState,
  AssociatedPropertiesState,
} from "../../state/admin/associated-property.state";

export function adminAssociatedPropertyReducer(
  state = associatedPropertiesInitialState,
  action: AdminAssociatedPropertyActions
): AssociatedPropertiesState {
  switch (action.type) {
    case AdminAssociatedPropertyTypes.GetAssociatedPropertySuccess: {
      return {
        ...state,
        associatedProperties: action.associatedProperties,
      };
    }
  }
}
