import { Action, createReducer, on } from '@ngrx/store';
import { IAdditionalInfoTypeState, initialAdditionalInfoTypeState } from '../../state/additionalInfo/additionalInfoType.state';
import * as additionalInfoTypeActions  from '../../actions/additionalInfo/additionalInfoType.actions';

const reducer = createReducer(
    initialAdditionalInfoTypeState,
    on(additionalInfoTypeActions.actionSetAdditionalInfoType, (state, {additionalInfoTypes})=>({
        ...state,
        additionalInfoTypes: additionalInfoTypes
    })),
    on(additionalInfoTypeActions.actionLoadAdditionalInfoTypeSuccess, (state, {additionalInfoTypes})=>({
        ...state,
        additionalInfoTypes: additionalInfoTypes
    })),
    on(additionalInfoTypeActions.actionLoadAdditionalInfoTypeFail, (state, {})=>({
        ...state,
        additionalInfoTypes: null
    })),
);

export function additionalInfoTypeReducers(state: IAdditionalInfoTypeState | undefined, action: Action) {
    return reducer(state, action);
}