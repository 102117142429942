
import { createReducer, on, Action } from '@ngrx/store';
import { initialDocumentState, IDocumentState } from '../../state/documentation/document.state';
import * as documentActions  from '../../actions/documentation/document.actions';

const reducer = createReducer(
    initialDocumentState,
    on(documentActions.actionSaveDocumentSuccess, (state, {document})=>({
        ...state,
        documents: document
    })),
    on(documentActions.actionSetDocument, (state, {document})=>({
        ...state,
        documents: document
    })),
    on(documentActions.actionSaveDocumentFail, (state, {})=>({
        ...state
    })),
    on(documentActions.actionFetchDocumentsFail, (state, {})=>({
        ...state,
        documents: null
    })),
    on(documentActions.actionClearDocumentState, () =>({
        ...initialDocumentState
    })),
    on(documentActions.actionToInspectDocument, (state, {isInspected})=>({
        ...state,
        isInspected
    })),
);

export function documentReducers(state: IDocumentState | undefined, action: Action) {
    return reducer(state, action);
}

