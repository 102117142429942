import { IReport } from '../../../interfaces/report.interface';

export interface IReportsState {
    reports: IReport[],
    isReportsExist: boolean
}

export const initialReportsState: IReportsState = {
    reports: [],
    isReportsExist: false
};