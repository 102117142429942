import { Action } from "@ngrx/store";
import {
  AdminUserList,
  UserOverviewDetails,
} from "src/app/interfaces/user-list.interface";

export enum AdminUserListActionTypes {
  ActionGetUser = "[Admin User List] Save User List",
  SaveUserDetails = "[Admin User List] Save User Details",
  ActionGetUserSuccess = "[Admin User List] Save User Success",
  ActionGetUserFailure = "[Admin User List] Save User Failure",
}

export class ActionGetUser implements Action {
  readonly type = AdminUserListActionTypes.ActionGetUser;
}
export class ActionGetUserSuccess implements Action {
  readonly type = AdminUserListActionTypes.ActionGetUserSuccess;
  constructor(public userList: AdminUserList[]) {}
}
export class ActionGetUserFailure implements Action {
  readonly type = AdminUserListActionTypes.ActionGetUserFailure;
  constructor(public err: any) {}
}

export class SaveUserDetails implements Action {
  readonly type = AdminUserListActionTypes.SaveUserDetails;
  constructor(public userOverviewDetails: UserOverviewDetails[]) {}
}

export type AdminUserListActions =
  | ActionGetUser
  | SaveUserDetails
  | ActionGetUserSuccess
  | ActionGetUserFailure;
