import { UserService } from './user.service';
import { IAppState } from './../store/state/app.state';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { InspectionService } from './inspection.service';
import { take } from 'rxjs/operators';
import * as fromInspection from 'src/app/store/selectors/inspection/inspection.selector';
import * as fromUser from 'src/app/store/selectors/user/user.selector';
import * as fromProperty from 'src/app/store/selectors/property/property.selector';
import { actionClearPropertyState } from 'src/app/store/actions/property/property.actions';
import { actionClearDocumentState } from 'src/app/store/actions/documentation/document.actions';
import { actionClearAdditionalInfoState } from 'src/app/store/actions/additionalInfo/additionalInfo.actions';
import { actionClearInventoryState } from 'src/app/store/actions/inventory/inventory.actions';
import { actionClearReadingsState } from 'src/app/store/actions/meterReadings/readings.actions';
import { actionClearReportState } from 'src/app/store/actions/report/report.actions';
import { actionClearReportsState } from 'src/app/store/actions/report/reports.actions';
import { actionClearSafetyState } from 'src/app/store/actions/safety/safety.actions';
import { actionClearSecurityState } from 'src/app/store/actions/security/security.actions';
import { actionClearInspectionState } from 'src/app/store/actions/inspection/inspection.actions';
import { actionMutateInspectionState } from 'src/app/store/actions/inspection/inspection.actions';
import {
  VIDEO_TYPES,
  IMAGE_TYPES,
} from 'src/app/constants/media-types.constant';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { Location } from '@angular/common';
import { RentahausToastService } from './toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TimeoutError } from 'rxjs';
import { Router } from '@angular/router';
import {
  REQUEST_STATUS_KEY,
  REQUEST_STATUS_VALUE,
} from '../constants/http-client.constant';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public isCompletedEvent = false;

  constructor(
    private inspectionService: InspectionService,
    private userService: UserService,
    private location: Location,
    private store: Store<IAppState>,
    private rentahausToastService: RentahausToastService,
    private iab: InAppBrowser,
    private router: Router
  ) {}

  public get isApprover(): boolean {
    let flag = false;
    this.store
      .select(fromUser.selectIsApprover)
      .pipe(take(1))
      .subscribe((f) => (flag = f));
    return flag;
  }

  public get isViewer(): boolean {
    return !this.isApprover;
  }

  public get isExternalUser(): boolean {
    let flag = false;
    this.store
      .select(fromUser.selectIsExternalUser)
      .pipe(take(1))
      .subscribe((f) => (flag = f || false));
    return flag;
  }

  public get getCurrentUserRole(): any {
    let role: string;
    this.store
      .select(fromUser.selectRole)
      .pipe(take(1))
      .subscribe((r) => (role = r || null));
    return role;
  }

  public get isReportViewer(): boolean {
    return this.getCurrentUserRole === 'ROLE_REPORT_VIEWER' ? true : false;
  }

  public get isInspectionEventView(): boolean {
    let flag = false;
    this.store
      .select(fromInspection.selectViewInspection)
      .pipe(take(1))
      .subscribe((f) => (flag = f || false));
    return flag;
  }

  public get showInspectionToggler(): boolean {
    return (
      this.inspectionModeEnabled &&
      !this.isExternalUser &&
      !this.isInspectionCompleted
    );
  }

  public get isNormalUser(): boolean {
    return !this.isExternalUser;
  }

  public get viewInspectionOrExternalUser(): boolean {
    return this.isExternalUser || this.isInspectionEventView;
  }

  public get todayDate(): Date {
    return new Date();
  }

  public get currentDateWithFormat(): string {
    const current = new Date();
    return current.toLocaleString('en-us', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }

  public get isInspectionCompleted(): boolean {
    return Boolean(
      this.inspectionService.inspectionData
        ? this.inspectionService.inspectionData.inspection.is_completed
        : false
    );
  }

  public get isInspectionApprovalRequired(): boolean {
    return this.inspectionService.inspectionData
      ? this.inspectionService.inspectionData.inspection.approval_required
      : false;
  }

  public get isInspectionShared(): boolean {
    return this.inspectionService.inspectionData
      ? this.inspectionService.inspectionData.inspection.is_shared
      : false;
  }

  public get isInspectionApproved(): boolean {
    return this.inspectionService.inspectionData
      ? this.inspectionService.inspectionData.inspection.is_approved
      : false;
  }

  public inspectionEventName(is_previous = false): string {
    if (is_previous) {
      return this.inspectionService.inspectionData
        ? this.inspectionService.inspectionData.inspection.previous_inspection
            .event
        : null;
    } else {
      return this.inspectionService.inspectionData
        ? this.inspectionService.inspectionData.inspection.event.name
        : null;
    }
  }

  public get inspectionModeEnabled(): boolean {
    let flag = false;
    this.store
      .select(fromInspection.selectInspectionMode)
      .pipe(take(1))
      .subscribe((f) => (flag = f));
    return flag;
  }

  public getSessionStorage(key: any) {
    return sessionStorage.getItem(key);
  }

  public inspectionDate(is_previous = false): Date {
    if (is_previous) {
      return new Date(
        this.inspectionService.inspectionData
          ? this.inspectionService.inspectionData.inspection.previous_inspection
              .performed_at
          : this.todayDate
      );
    } else {
      return new Date(
        this.inspectionService.inspectionData
          ? this.inspectionService.inspectionData.inspection.performed_at_date
          : this.todayDate
      );
    }
  }

  public getDayName(is_previous = false) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    if (is_previous) {
    const d = new Date(this.inspectionService.inspectionData
      ? this.inspectionService.inspectionData.inspection.previous_inspection
          .performed_at
      : this.todayDate);
    const dayName = days[d.getDay()];
    return dayName;
    } else {
      const d = new Date(this.inspectionService.inspectionData
        ? this.inspectionService.inspectionData.inspection.performed_at_date
        : this.todayDate);
      const dayName = days[d.getDay()] ;
      return dayName;
    }
  }

  public back(): void {
    this.location.back();
  }

  public get isPropertyActivate(): boolean {
    let flag = false;
    this.store
      .select(fromProperty.selectCurrentProperty)
      .pipe(take(1))
      .subscribe((f) => (flag = (f && f.isActive) || false));
    return flag;
  }

  public get isHideEditButton(): boolean {
    return this.viewInspectionOrExternalUser || !this.isPropertyActivate;
  }

  public get toOverview(): boolean {
    return this.viewInspectionOrExternalUser || !this.isPropertyActivate;
  }

  public clearStates(clearInspection = false, redirect = false): void {
    this.inspectionService.inspectionData = null;
    this.store.dispatch(actionClearPropertyState());
    this.store.dispatch(actionClearInventoryState());
    this.store.dispatch(actionClearDocumentState());
    this.store.dispatch(actionClearAdditionalInfoState());
    this.store.dispatch(actionClearReadingsState());
    this.store.dispatch(actionClearReportState());
    this.store.dispatch(actionClearReportsState());
    this.store.dispatch(actionClearSafetyState());
    this.store.dispatch(actionClearSecurityState());
    if (clearInspection) {
      this.store.dispatch(actionClearInspectionState());
    }
    if (redirect) {
      this.router.navigate(['/property'], { replaceUrl: true });
    }
  }

  public setUserSession(token: string, shared_token: string, user: any): void {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('shared_token', shared_token);
    sessionStorage.setItem('user', JSON.stringify(user));
    this.userService.userStore(
      user,
      token,
      shared_token,
    );
  }

  public mutateToInspectedState(
    propertyId: string,
    inspectionId: string,
    state: string,
    key: string,
    value: boolean,
    token = null
  ): void {
    this.store.dispatch(
      actionMutateInspectionState({
        propertyId,
        data: { inspection_id: inspectionId, state, key, value, token },
      })
    );
  }

  public setPropertyAddress(...format: any): string {
    return format.filter(Boolean).join(', ');
  }

  public get durationLimit(): number {
    return 11;
  }

  public videoDurationToast(names: string): void {
    this.rentahausToastService.showToast(
      'error',
      this.toasterTitle,
      `Max duration: ${
        this.durationLimit - 1
      }sec, Video duration exceeded for file's: ${names}`
    );
  }

  public requestLimitToast(): void {
    this.rentahausToastService.showToast(
      'error',
      this.toasterTitle,
      'Sorry, videos are unable to load right now. Please try adding a photo'
    );
  }

  public successToaster(message: string): void {
    this.rentahausToastService.showToast('success', this.toasterTitle, message);
  }

  public errorToaster(message: string): void {
    this.rentahausToastService.showToast('error', this.toasterTitle, message);
  }

  public get toasterTitle(): string {
    return 'Inventory Volt';
  }

  public somethingWentWrong(): void {
    this.rentahausToastService.showToast(
      'error',
      this.toasterTitle,
      'Something went wrong, please try again later'
    );
  }

  public isPdf(name: string): boolean {
    return name.split('.').pop().toLocaleLowerCase().replace('.', '') === 'pdf';
  }

  public isVideo(name: string): boolean {
    const ext = name.split('.').pop().toLocaleLowerCase();
    return VIDEO_TYPES.some((type) => type.replace('.', '') === ext);
  }

  public isImage(name: string): boolean {
    const ext = name.split('.').pop().toLocaleLowerCase();
    return IMAGE_TYPES.some((type) => type.replace('.', '') === ext);
  }

  public convertImageLinksFromAppToApi(name: string): string {
    name = name.replace('app.stage', 'api.stage');
    return name.replace('app.', 'api.');
  }

  public openInAppBrowser(link: string): void {
    if (link !== '') {
      this.iab.create(link.trim());
    } else {
      this.somethingWentWrong();
    }
  }

  public getUser(): any {
    return JSON.parse(sessionStorage.getItem('user'));
  }

  public get userEmail(): string {
    return this.getUser() ? this.getUser().email : '';
  }

  public get userName(): string {
    return this.getUser() ? this.getUser().name : '';
  }

  public offlineOrBadConnection(err: HttpErrorResponse): boolean {
    return (
      err instanceof TimeoutError ||
      err.error instanceof ErrorEvent ||
      !window.navigator.onLine
    );
  }

  public getExtension(filename: string): string {
    return filename.split('.').pop();
  }

  public getFileName(filename: string): string {
    return filename.split('.').slice(0, -1).join('.');
  }

  public getRandFileName(filename: string): string {
    const r = (Math.random() + 1).toString(36).substring(7);
    const time = new Date().getTime();
    const ext = this.getExtension(filename);
    const setFileName = `selection-${r}-${time}.${ext}`;
    return setFileName;
  }

  // TODO - check this functionality
  /* request in pending state */
  public setPendingStatus() {
    this.deletePendingStatus();
    sessionStorage.setItem(REQUEST_STATUS_KEY, REQUEST_STATUS_VALUE);
  }

  public get getPendingStatus(): boolean {
    return sessionStorage.getItem(REQUEST_STATUS_KEY) === 'pending';
  }

  public deletePendingStatus() {
    sessionStorage.removeItem(REQUEST_STATUS_KEY);
  }

  public get isInspectionAwaitingState(): boolean {
    return this.inspectionService.inspectionData &&
        this.inspectionService.inspectionData.inspection.approval_required &&
        this.inspectionService.inspectionData.inspection.is_completed &&
        this.inspectionModeEnabled;
  }

  public setCompletedEventState(state: boolean): void {
    this.isCompletedEvent = state;
  }

  public get returnConfirmContent(): any {
    const title = 'Are you sure you want to back?';
    const content =
      'By selecting YES any changes you’ve made will be lost, if you wish to continue with the changes press CANCEL and save changes first';
    return { title, content };
  }

  public get returnConfirmContentWithMissingField(): any {
    const title = 'Are you sure you want to back?';
    const content =
      'By selecting YES any changes you’ve made will be lost, if you wish to continue with the changes press CANCEL ' +
        'and fill out red hightlight field first';
    return { title, content };
  }

  public utcToLocalTime(date: any, time: string): string {
    const setDateTime = `${date.replaceAll('-', '/', 'pm', 'PM', 'am', 'AM')} UTC`;
    const yourDate = new Date(setDateTime);
    return yourDate.toString();
  }
}
